import * as models from 'models/index';
import { appMaxWidth, media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  app_container: {
    backgroundColor: '#fff',
    height: 'auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    zIndex: 0,
    position: 'relative',
    pointerEvents: styles.modalOpen? 'none' : 'normal',
    ...styles.globalStyles.font,
    ...styles.pageStyles
  },

  app: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0 auto',
    maxWidth: appMaxWidth,
    width: '100%',
    paddingBottom: 30,
    ...styles.globalStyles.container,

    [media.tablet]: {
      paddingBottom: 50,
      ...styles.globalStyles.container.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.container.desktop
    }
  },

  page: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    ...styles.globalStyles.font,
    ...styles.pageStyles
  }

});
