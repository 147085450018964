import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import { CloseIcon } from 'util/icons';
import { style } from './style';

class Modal extends React.Component<models.store.IAppProps> {

  modalRef: any = React.createRef();
  focusableEls: any = [];
  firstFocusableEl: any = null;
  lastFocusableEl: any = null;

  UNSAFE_componentWillMount(): void {
    // this.props.globalFn.storePosition();
    document.body.classList.add('no-scroll');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keydown', this._handleKeyDown);
    // this.props.globalFn.scrollToStoredPosition();
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this._handleKeyDown);
    requestAnimationFrame(() => this._trapFocus());
    this.props.globalFn.scrollToTop();
  }

  componentDidUpdate(prevProps: models.store.IAppProps): void {
    if (prevProps.modalProps.type !== this.props.modalProps.type) {
      this._trapFocus();
      this.props.globalFn.scrollToTop();
    }
  }

  render() {
    const styles = style({
      globalStyles: this.props.stylesData.global,
      modalStyles: this.props.stylesData.modal
    });

    return (
      <dialog
        className={css(styles.modal)}
        onClick={this.props.modalFn.closeModal}
        ref={this.modalRef}>

        <div className={css(styles.container)}>
          <div className={css(styles.content)} onClick={(e) => { e.stopPropagation() }}>
            <button
              aria-label='close modal'
              onClick={this.props.modalFn.closeModal}
              className={css(styles.close)}>
              <CloseIcon />
            </button>

            {this.props.children}
          </div>
        </div>

      </dialog>
    );
  }

 _trapFocus = () => {
   if (!this.modalRef.current) return;

    let focusableEls = this.modalRef.current.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
    this.focusableEls = Array.prototype.slice.call(focusableEls);

    this.firstFocusableEl = focusableEls[0];
    this.lastFocusableEl = focusableEls[focusableEls.length - 1];

    this.firstFocusableEl.focus();
  }

  _handleKeyDown = (e: any) => {

    switch (e.keyCode) {

      case constants.KEYS.ESCAPE:
        this.props.modalFn.closeModal();
        break;

      case constants.KEYS.TAB:
        if (this.focusableEls.length === 1) {
          break;
        }

        if (e.shiftKey) {
          if (document.activeElement === this.firstFocusableEl) {
            e.preventDefault();
            this.lastFocusableEl.focus();
          }
        } else {
          if (document.activeElement === this.lastFocusableEl) {
            e.preventDefault();
            this.firstFocusableEl.focus();
          }
        }
        break;

      default:
        break;
    }
  }
}

export default Connect(Modal);
