import * as models from 'models/index';
import * as helpers from 'util/helpers';
import { appMaxWidth, children, darkenColor, media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  footer: {
    backgroundColor: '#aaa',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    padding: 0,
    maxWidth: helpers.checkIfTrue(styles.settings.displayBleed) ? 'none' : appMaxWidth,
    width: '100%',
    minHeight: '40px',
    ...styles.footerStyles.general,
    backgroundImage: styles.footerStyles.backgroundImage? `url(${styles.footerStyles.backgroundImage})`: 'none',

    [media.tablet]: {
      minHeight: '60px',
      ...styles.footerStyles.general.tablet
    }
  },

  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    textAlign: 'center',
    flexGrow: 1,
    height: '100%',
    maxWidth: appMaxWidth,
    margin: 'auto',
    width: '100%',
    padding: '10px 0 20px 0',
    ...styles.globalStyles.container,
    ...styles.footerStyles.container,

    [media.tablet]: {
      ...styles.globalStyles.container.tablet,
      ...styles.footerStyles.container.tablet,
    },

    [media.desktop]: {
      ...styles.globalStyles.container.desktop,
      ...styles.footerStyles.container.desktop,
    }
  },

  disclaimer: {
    maxWidth: appMaxWidth,
    margin: '0 auto',
    paddingTop: '20px',
    textAlign: 'center',
    ...styles.footerStyles.disclaimer,

    [children('a')]: {
      color: 'inherit'
    },

    [media.tablet]: {
      ...styles.footerStyles.disclaimer.tablet,
    },

    [media.desktop]: {
      ...styles.footerStyles.disclaimer.desktop,
    }
  },

  footer_link: {
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    ...styles.footerStyles.link,

    [media.tablet]: {
      ...styles.footerStyles.link.tablet
    },

    [media.desktop]: {
      ...styles.footerStyles.link.desktop
    },

    ':hover': {
      color: darkenColor(styles.footerStyles.link.color)
    },

    ':focus': {
      color: darkenColor(styles.footerStyles.link.color)
    },

    ':first-of-type': {
      marginLeft: '0'
    },

    ':last-of-type': {
      marginRight: '0'
    },
  },

  separator: {
    ...styles.footerStyles.separator,

    ':last-child': {
      display: 'none'
    },

    [media.tablet]: {
      ...styles.footerStyles.separator.tablet
    },

    [media.desktop]: {
      ...styles.footerStyles.separator.desktop
    },
  }
});
