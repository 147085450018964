import { useState } from 'react';
import { AppStateProvider } from 'store/index';
import * as googleHelpers from 'util/google-helpers';
import * as constants from 'util/constants';
import * as models from 'models/index';
import * as helpers from 'util/helpers';
import api from 'util/api';

export function submitVote(this: AppStateProvider, voteCount: number): void {
  const { contestantId, category } = this.state.globalProps;
  const payload = this.state.apiFn.getDefaultPayload(constants.ACTION_TYPES.VOTE, category?.id);
  payload.total = voteCount;
  payload.contestant = contestantId;

  const gaAction = `${constants.GA_EVENTS.SUBMIT_VOTE}: ${category.id}: ${contestantId}`;
  googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, gaAction, '');

  return api.postToVoteApi(payload, this.state.cmsData.settings.version_id)
  .then((response: any) => {
    let errorType = '';

    switch (response.response_code) {
      case constants.RESPONSE_CODES.VALID: 
        this.state.voteFn.handleVoteSuccess(response);
        return;
      case constants.RESPONSE_CODES.OUT_OF_WINDOW:
        errorType = constants.MODAL_TYPES.errorWindow;
        break;
      case constants.RESPONSE_CODES.OVERLIMIT:
        errorType = constants.MODAL_TYPES.errorOverlimit;
        break;
      case constants.RESPONSE_CODES.GENERAL_INVALID:
      default:
        errorType = constants.MODAL_TYPES.errorGeneric;
        break;
    }

    this.state.voteFn.handleVoteError(errorType);
  })
  .catch(() => {
    this.state.voteFn.handleVoteError(constants.MODAL_TYPES.errorGeneric);
  });
}

export async function handleVoteSuccess(this: AppStateProvider, response: models.base.IGenericObject): Promise<void> {
  const voteString = response['votestring'];
  const history = voteString ? { ...JSON.parse(voteString) } : {};

  await this.state.voteFn.updateVoteHistory(history);
  this.state.modalFn.openModal(constants.MODAL_TYPES.confirmation);
}

export function handleVoteError(this: AppStateProvider, errorType: string): void {
  this.state.modalFn.openModal(errorType);
}

export function useVoteAttempt(userData: any) {
  const { isAuthorized, email, facebook_email, twitter_email } = userData
  const [voteAttempt, setVoteAttempt]:[boolean, any] = useState(false);

  const isUserValid = isAuthorized && ( email || facebook_email || twitter_email )

  return { voteAttempt, setVoteAttempt, isUserValid }
}


export async function getVoteHistory(this: AppStateProvider): Promise<any> {
  const { globalProps, cmsData, apiFn, isCategoryVote } = this.state;
  const { userData, category } = globalProps;

  if (!userData.isAuthorized || ( isCategoryVote && !category?.id )) return;

  const payload = apiFn.getHistoryPayload(category?.id);
  const response = await api.postToVoteApi(payload, cmsData.settings.version_id);
  let history = {};

  if (response && response.response_code === constants.RESPONSE_CODES.VALID) {
    const voteString = response['votestring'];
    history = voteString ? { ...JSON.parse(voteString) } : {};
  }

  return this.state.voteFn.updateVoteHistory(history);

  // Sample response
  // { votestring: "{"total":4,"cat5-A5":4}" response_code: "20" }
  // { votestring: "{"total":4,"A5":4}" response_code: "20" }
}

export async function updateVoteHistory(this: AppStateProvider, history: models.base.IGenericObject): Promise<models.store.IAppProps> {
  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        voteHistory: history
      };
    }, () => resolve(this));
  });
}


export function getContestantVotes(this: AppStateProvider, contestantId: string): number {
  const { globalProps: { category }, isCategoryVote, voteHistory } = this.state

  const contestantKey = isCategoryVote? `cat${category.id}-${contestantId}` : contestantId; 

  return voteHistory[contestantKey] || 0;
}

export function getCategoryVotes(this: AppStateProvider): number {
  const { voteHistory } = this.state

  return voteHistory.total || 0;
}