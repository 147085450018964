import { css, StyleSheet } from 'aphrodite/no-important';
import React, { FC, useState } from 'react';
import { LocalStorage } from 'ttl-localstorage';
import { CookieConsentClassNames, Layout, style } from './style';

const ONE_DAY_IN_SECONDS = 86400;

interface CookieConsentProps {
  /**
   * Key to use for localstorage when consent is accepted or declined
   */
  storageKey: string;
  /**
   * Callback function to be called when user clicks the 'Accept' button.
   */
  onAccept: () => void;
  /**
   * Callback function to be called when user clicks the 'Decline' button.
   */
  onDecline?: () => void;
  /**
   * Number of days before asking for consent again. Default is 365.
   */
  expires?: number;
  /**
   * If true, will allow the user to decline consent. Defaults to true.
   */
  showDeclineButton?: boolean;
  /**
   * Layout of the container. If set to 'row', the action buttons are displayed to the right of the text.
   * If set to 'column', buttons sit below the text. Default is 'row'.
   */
  layout?: Layout;
  /**
   * If true, content stretches the root element to full viewport width, else set to maxAppWidth
   */
  fluid?: boolean;
  /**
   * Text to show for AcceptButton
   */
  acceptButtonLabel?: string;
  /**
   * Text to show for DeclineButton
   */
  declineButtonLabel?: string;
  /**
   * Object to override default styles
   */
  styleOverrides?: CookieConsentClassNames;
}

const CookieConsent: FC<CookieConsentProps> = ({
  onAccept,
  onDecline,
  expires = 365,
  storageKey,
  layout = 'row',
  fluid = false,
  acceptButtonLabel = 'Accept',
  declineButtonLabel = 'Decline',
  showDeclineButton = false,
  styleOverrides = {},
  children,
}) => {
  const styles = style({ layout, fluid, links: styleOverrides.links });
  const customStyles = StyleSheet.create(styleOverrides);
  const [visible, setVisible] = useState(true);

  const handleAcceptConsent = () => {
    LocalStorage.put(storageKey, true, expires * ONE_DAY_IN_SECONDS);
    setVisible(false);
    onAccept();
  };

  const handleDeclineConsent = () => {
    LocalStorage.put(storageKey, false, expires * ONE_DAY_IN_SECONDS);
    setVisible(false);

    onDecline && onDecline();
  };

  // if consent has already been accepted/declined don't render banner
  if (!visible || LocalStorage.keyExists(storageKey)) {
    return null;
  }

  return (
    <div className={css(styles.root, customStyles.root)} role='dialog'>
      <div className={css(styles.container, customStyles.container)}>
        <div className={css(styles.text, customStyles.text)}>{children}</div>
        <div className={css(styles.actions, customStyles.actions)}>
          <button
            className={css(styles.acceptButton, customStyles.acceptButton)}
            onClick={handleAcceptConsent}
          >
            {acceptButtonLabel}
          </button>
          {showDeclineButton && (
            <button
              className={css(styles.declineButton, customStyles?.declineButton)}
              onClick={handleDeclineConsent}
            >
              {declineButtonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
