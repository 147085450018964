import { css } from 'aphrodite/no-important';
import parse from 'html-react-parser';
import { AuthActionType, IEmailLoginPayload, IFacebookLoginPayload, ITwitterLoginPayload } from 'models/auth';
import { IGenericObject } from 'models/base';
import * as models from 'models/index';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useForm, ValidationRule } from 'react-hook-form';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import { AUTH_METHODS } from 'util/constants';
import * as fbHelpers from 'util/fb-helpers';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { FacebookIcon, TwitterIcon } from 'util/icons';
import { storageFactory } from 'util/storage_helpers';
import { login } from 'util/twitter';
import { style } from './style';

const localStore = storageFactory(localStorage);

const storeUserData = (method: string, id: string, payload: IGenericObject) => {
  const storePayload = JSON.stringify({ method, id, ...payload });
  localStore.setItem(constants.AUTH_LOCALSTORAGE_LABEL, storePayload);
};

function Login(props: models.store.IAppProps & models.login.ILoginSwap) {
  const settings = props.cmsData.text.login.settings;
  const copy = props.copyData.text.login;
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [method, setMethod] = useState('');
  const [authAction, setAuthAction] = useState<AuthActionType>('login');
  const watchSweeps = watch('optin_sweeps');

  const styles = style({
    globalStyles: props.stylesData.global,
    loginStyles: props.stylesData.login,
    buttonStyles: props.stylesData.login.buttons
  });

  const { display_facebook, display_email, display_twitter } = settings;
  const hasMultipleMethods = [ display_facebook, display_email, display_twitter ]
    .filter((value) => helpers.checkIfTrue(value)).length > 1;


  const emailLogin = useCallback(async (data: IGenericObject) => {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.EMAIL_LOGIN, '');
    try {
      const emailPayload = {
        method: AUTH_METHODS.EMAIL,
        user_id: data.email,
        ...data
      }  as IEmailLoginPayload;
      
      
      const response = await (authAction === 'register' ? props.authFn.submitRegistration(emailPayload) : props.authFn.submitLogin(emailPayload));
      
      switch (response.response_code) {
        case constants.RESPONSE_CODES.VALID_LOGIN:
        case constants.RESPONSE_CODES.VALID_REGISTER:
          await props.authFn.login(emailPayload, AUTH_METHODS.EMAIL);
          storeUserData(AUTH_METHODS.EMAIL, data.email, emailPayload);
          break;
        case constants.RESPONSE_CODES.GENERAL_INVALID:
          setAuthAction('register');
          break;
        default:
          console.error('Failed to login: ', response.response_code );
      }
      
    } catch (e) {
     console.error('Email Login Failed.')
    }
    
  }, [ props.authFn, authAction ]);

  const twitterLogin = useCallback(async (data : IGenericObject) => {
    try {
      const { twitter_auth_key, twitter_auth_url } = props.cmsData.settings;
      const twitterPayload = await login(twitter_auth_key, twitter_auth_url);

      const payload = { jwt: twitterPayload, ...data, ...twitterPayload.user } as ITwitterLoginPayload;
      await props.authFn.login(payload, AUTH_METHODS.TWITTER);

      storeUserData(AUTH_METHODS.TWITTER, twitterPayload.token, data);

    } catch(e) {
      console.error('twitter login failed', e)
    }
  }, [props.authFn, props.cmsData.settings]);

  const facebookLogin = useCallback((data: IGenericObject) => {

    return window.FB.login(() => {
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.FACEBOOK_LOGIN,'');

      fbHelpers.checkLoginState()
        .then(response => {

          if (!response.id) {
            return window.FB.logout(() => {
              props.loginFn.setFbError(fbHelpers.FB_ERROR_TYPE.GENERIC);
            });
          }

          if (!response.email) {
            return window.FB.logout(() => {
              props.loginFn.setFbError(fbHelpers.FB_ERROR_TYPE.PERMISSIONS);
            });
          }

          const resPayload = {
            method: AUTH_METHODS.FACEBOOK,
            facebook_email: response.email,
            fb_first_name: response.first_name,
            fb_last_name: response.last_name,
            user_id: response.id
          }

          const payload = { ...data, ...resPayload } as IFacebookLoginPayload;

          storeUserData(AUTH_METHODS.FACEBOOK, response.email, payload);
          
          // TODO: Need to fix this. We shouldn't set user data until after a successful login
          props.authFn.submitLogin(payload).then(() => { });
          props.authFn.login(payload, AUTH_METHODS.FACEBOOK).then(() => {
            props.authFn.submitLogin(payload);
          });
        })
        .catch(() => {
          props.loginFn.setFbError(fbHelpers.FB_ERROR_TYPE.GENERIC);
        });
    },
      {
        auth_type: constants.FACEBOOK_AUTH_TYPES.REREQUEST,
        scope: props.cmsData.social.fb.scope
      });
  }, [props.authFn, props.cmsData.social.fb.scope, props.loginFn]);

  const onSubmit = useCallback(() => {
    handleSubmit(async (data: IGenericObject) => {

      switch (method) {
        case AUTH_METHODS.FACEBOOK:
          facebookLogin(data);
          break;
        case AUTH_METHODS.TWITTER:
          twitterLogin(data);
          break;
        case AUTH_METHODS.EMAIL:
        default:
          emailLogin(data);
          break;
      }
    })();
  }, [method, handleSubmit, emailLogin, facebookLogin, twitterLogin]);

  useEffect(() => {
    if (method) {
      onSubmit()
    }
  }, [method, onSubmit]);

  const onSubmitClick = (e: React.SyntheticEvent, submitMethod: string) => {
    e.preventDefault();

    if (method !== submitMethod) {
      return setMethod(submitMethod)
    }

    onSubmit();
  }

  /**
   * Render form fields. If the user is registering for the first time, display all fields. 
   * If the user is logging in, only show fields needed for every login
   */
  const renderInputs = (inputArray: IGenericObject[]) => {
    let filteredInputs = [...inputArray];

    if (authAction === 'login') {
      const { fields_displayed_on_subsequent_logins: displayedFields } = settings;
      filteredInputs = filteredInputs.filter(el => displayedFields.includes(el.name))
    }

    // ITHF-33, ITHF-8 - Don't render zip or phone fields unless user has opted in to sweepstakes
    if (!watchSweeps) {
      filteredInputs = filteredInputs.filter(el => !['zipcode', 'phone_number'].includes(el.name));
    }
      
      return filteredInputs.map(el => renderInput(el));
       
  }

  const trimValue = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    target.value = target.value.trim();
  }

  const renderInput = (field: IGenericObject) => {
    const hasError = errors[field.name];
    const validation = field.validation?
      helpers.convertRegExp(field.validation) as ValidationRule<RegExp> : undefined;
    let isRequired = helpers.checkIfTrue(field.required);

    switch (field.type) {

      case 'checkbox':
        return (
          <div className={css(styles.formGroup)} key={field.name}>
            <input type={field.type}
              id={field.name}
              className={css(styles.checkboxInput)}
              {...register( field.name, { required: isRequired })} />
            <label htmlFor={field.name} className={css( styles.checkbox, hasError && styles.hasError )} />
            <label htmlFor={field.name} className={css( styles.label, styles.checkboxLabel )}>
              { parse(field.label) }
            </label>
            { hasError &&
              <p className={css( styles.errorMessage, styles.checkboxErrorMessage )}> { parse(field.error_message) } </p> }
          </div>
        )

      case 'email':
      case 'text':
      default:

        // If auth method is email, 'email' is required
        isRequired = field.name === 'email' ? method === AUTH_METHODS.EMAIL : isRequired;

        return (
          <div className={css(styles.formGroup)} key={field.name}>
            <label className={css(styles.label)} htmlFor={field.name}> {field.label} </label>
            <input type={field.type}
              id={field.name}
              className={css( styles.input, hasError && styles.hasError )}
              placeholder={field.placeholder}
              maxLength={field.max_length? parseInt(field.max_length, 10) : undefined}
              {...register( field.name, { required: isRequired, pattern: validation })}
              onBlur={e => trimValue(e)} />
            { hasError &&
              <p className={css(styles.errorMessage)}> { parse(field.error_message) } </p> }
          </div>
        )
    }
  }

  if (props.loginProps.fbError) {
    return <props.SwapError />;
  }

  return (
    <div aria-label='Log in' className={css(styles.login)}>

      <h2 className={css(styles.headline)}> { copy.headline } </h2>

      <form className={css(styles.loginForm)}>

        { copy.global_inputs[0].name &&
        <div className={css(styles.globalInputsContainer, styles.formSection)}>
          { renderInputs(copy.global_inputs) }
        </div>  }

        <div className={css(styles.optinsContainer, styles.formSection)}>
          { renderInputs(copy.optins) }
        </div>

        <div className={css(styles.methodsContainer, styles.formSection)}>

          { hasMultipleMethods && copy.sign_in_options &&
            <h3> { copy.sign_in_options } </h3> }

          { helpers.checkIfTrue(display_facebook) &&
            <button type="button"
              className={css(styles.button, styles.facebook_button)}
              onClick={(e) => onSubmitClick(e, AUTH_METHODS.FACEBOOK)} >
              <FacebookIcon />
              { copy.buttons.facebook }
            </button> }

          { helpers.checkIfTrue(settings.display_twitter) &&
            <button type="button"
              className={css(styles.button, styles.twitter_button)}
              onClick={(e) => onSubmitClick(e, AUTH_METHODS.TWITTER)} >
              <TwitterIcon />
              { copy.buttons.twitter }
            </button> }

          { helpers.checkIfTrue(display_email) &&
          <>

          { hasMultipleMethods &&
            <div className={css(styles.divider)}>
              <span className={css(styles.divider_line)} />
              <span className={css(styles.divider_text)}> OR </span>
              <span className={css(styles.divider_line)} />
            </div> }

          <div className={css(styles.formSection)}>

            <button type="button"
                className={css(styles.button, styles.email_button)}
                onClick={(e) => onSubmitClick(e, AUTH_METHODS.EMAIL)} >
                { copy.buttons.email }
            </button>

          </div>

          </>
          }

        </div>

      </form>
      {authAction === 'login' && <div className={css(styles.registerCta)}><span>{parse(copy.register_cta.cta_text)}</span> <button className={css(styles.registerCtaButton)} onClick={() => setAuthAction('register')}>{parse(copy.register_cta.cta_button_text)}</button></div>}

    </div>
  )
}

export default Connect(Login);
