import * as models from 'models/index';
import { AppStateProvider } from 'store/index';

export function setFbError(this: AppStateProvider, fbError: string): void {
  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      loginProps: {
        fbError
      }
    };
  });
};