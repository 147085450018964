import * as models from 'models/index';
import { media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  categories: {
    padding: 0,
    marginBottom: 35,
    flexGrow: 2
  },

  categoriesContainer: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: `repeat(${styles.categoryStyles.columns.mobile}, 1fr)`,

    [media.tablet]: {
      gridTemplateColumns: `repeat(${styles.categoryStyles.columns.tablet}, 1fr)`,
    },

    [media.desktop]: {
      gridTemplateColumns: `repeat(${styles.categoryStyles.columns.desktop}, 1fr)`,
    },
  },

  category: {
    width: '100%',
    marginBottom: 0,
    padding: 0
  },

  button: {
    padding: 20,
    width: '100%',
    textDecoration: 'none',
    textAlign: 'center',
    ...styles.categoryStyles.categoryButton,

    [media.tablet]: {
      ...styles.categoryStyles.categoryButton.tablet,
    },

    [media.desktop]: {
      ...styles.categoryStyles.categoryButton.desktop,
    }
  },

  disabled: {
    background: '#ccc',
    cursor: 'default'
  },

  headline: {
    paddingBottom: 0,
    marginBottom: 20,
    ...styles.globalStyles.headlines,
    ...styles.categoryStyles.headline,

    [media.tablet]: {
      fontSize: 24,
      ...styles.globalStyles.headlines.tablet,
      ...styles.categoryStyles.headline.tablet,
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.categoryStyles.headline.desktop
    }
  },

  description: {
    fontSize: 18,
    marginBottom: 15,
    ...styles.categoryStyles.description,

    [media.tablet]: {
      fontSize: 24,
      ...styles.categoryStyles.description.tablet,
    },

    [media.desktop]: {
      ...styles.categoryStyles.description.desktop
    }
  }
});
