import * as models from 'models/index';
import { media, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  navigation: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    padding: '15px 0',
    ...styles.navigationStyles.general,

    [media.tablet]: {

      ...styles.navigationStyles.general.tablet
    },

    [media.desktop]: {
      ...styles.navigationStyles.general.desktop
    },


    [children('a')]: {
      textDecoration: 'none',
      padding: 0,
      margin: 0,
      transform: 'translateY(2px)'
    }
  },

  button: {
    margin: 0,
    fontSize: 14,
    ...styles.navigationStyles.links,

    [media.tablet]: {

      ...styles.navigationStyles.links.tablet
    },

    [media.desktop]: {
      ...styles.navigationStyles.links.desktop
    },

    [children('svg')]: {
      height: 15,
      width: 'auto',
      ...styles.navigationStyles.icons,

      [media.tablet]: {

        ...styles.navigationStyles.icons.tablet
      },

      [media.desktop]: {
        ...styles.navigationStyles.icons.desktop
      }
    },

    [children('i')]: {
      padding: '0 7px',
      display: 'inline-flex',
    },

    [children('span')]: {
      padding: 0
    },

    [children('> span')]: {
      display: 'flex',
      alignItems: 'center',
    }
  },

  prevButton: {
    textAlign: 'left',
    
    [children('> span')]: {
      justifyContent: 'start',
    }
  },

  nextButton: {
    textAlign: 'right',

    [children('> span')]: {
      justifyContent: 'end',
    }
  }
});
