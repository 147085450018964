import { StyleDeclaration, StyleSheet } from 'aphrodite/no-important';
import { appMaxWidth, children } from 'util/style-variables';

export type Layout = 'row' | 'column';

export interface CookieConstentStylesProps {
  layout: Layout;
  fluid: boolean;
  links: StyleDeclaration;
}

export interface CookieConsentClassNames {
  root?: StyleDeclaration;
  container?: StyleDeclaration;
  text?: StyleDeclaration;
  actions?: StyleDeclaration;
  links?: StyleDeclaration;
  acceptButton?: StyleDeclaration;
  declineButton?: StyleDeclaration;
}

export const style: any = (props: CookieConstentStylesProps) =>
  StyleSheet.create({
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: 20,
      background: '#000',
      color: '#FFF',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      maxWidth: props.fluid ? '100%' : appMaxWidth,
      flexDirection: props.layout,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      textAlign: 'left',
      flex: '1 1 auto',
      [children('a')]: {
        color: 'inherit',
        ...props.links
      }
    },
    actions: {
      flex: '1 1 auto',
      display: 'flex',
      flexWrap: 'nowrap',
      [props.layout === 'column' ? 'marginTop' : 'marginLeft']: 10,
    },
    
    acceptButton: {
      background: '#000',
      border: '1px solid #FFF',
      color: 'inherit',
      padding: '8px 12px',
      whiteSpace: 'nowrap',
    },
    declineButton: {
      background: '#000',
      border: 'none',
      color: 'inherit',
      whiteSpace: 'nowrap',
    },
  });
