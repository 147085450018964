import * as models from 'models/index';
import { IAppProps } from 'models/store';
import { AppStateProvider } from 'store/index';
import { normalizeStyles, checkIfTrue } from 'util/helpers';

export function storeCmsData(this: AppStateProvider, cmsData: models.api.ICmsData, copyData?: models.api.ICmsData, contestantsData?: models.api.ICmsData, stylesData?: models.base.IGenericObject): Promise<IAppProps> {
  const isCategoryVote = checkIfTrue(cmsData.settings.enable_category_vote);
  const isMultiVote = checkIfTrue(cmsData.settings.enable_multi_vote);

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prev: models.store.IAppProps) => {
      return {
        ...prev,
        cmsData,
        copyData,
        contestantsData,
        stylesData: normalizeStyles(stylesData),
        isCategoryVote,
        isMultiVote,
        isAppReady: true
      };
    }, () => resolve(this));
  });
};