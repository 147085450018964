import * as React from 'react';
import { AppStateProvider } from 'store/index';
import axios, { AxiosResponse } from 'axios';
import Base64 from "crypto-js/enc-base64";
import WordArray from "crypto-js/lib-typedarrays";

const API_URLS = {
  'local': 'https://twitterauth-local.votenow.tv:4000/api/v1/auth/login',
  'dev': 'https://twitterauth-dev.votenow.tv/api/v1/auth/login',
  'test': 'https://twitterauth-test.votenow.tv/api/v1/auth/login',
  'prod': 'https://twitterauth.votenow.tv/api/v1/auth/login'
}

const POPUP_PARAMS = 'width=585,height=700';
let promise: null | Promise<any>;
let popupRef: any;

const TWITTER_ENDPOINTS = {
  LOGIN: '/auth/login',
  SESSION: '/auth/me'
};
/**
 * if the user previously logged in via twitter, the jwt is saved.
 * we will then checkeck if this jwt is valid/has not expired
 */
export function loginWithJwt(jwt: string, campaignKey: string, authUrl: string): Promise<any> {
  const url = authUrl + TWITTER_ENDPOINTS.SESSION;

  return axios
    .post(`${url}?campaignKey=${campaignKey}`, {}, {
      headers: { 'x-auth-token': jwt },
    })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(err => {
      console.error('loginWithJwt failed', err);
      throw new Error(err);
  });

}

export function login(campaignKey: string, authUrl: string): Promise<any> {
  const url = authUrl + TWITTER_ENDPOINTS.LOGIN;

  return new Promise((resolve: (value: any) => void, reject: any) => {
    loginWithTwitter({ campaignKey, url, env: 'dev' })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

const loginWithTwitter = ({ campaignKey, env = 'prod', url = '', popupTarget = 'TwitterLogin' }: { campaignKey: string, env?: string, url?: string, popupTarget?: string }): Promise<any> => {

  if ( promise ) {
    if( popupRef ) {
      popupRef.focus();
    }
    return promise;
  }

  promise = new Promise( ( resolve, reject ) => {
    if( !API_URLS[env] ) {
      reject( 'Invalid environment' );
    }

    const twitterLoginUrl = url || API_URLS[env];
    const randomBytes = WordArray.random(16);
    const messageId = Base64.stringify( randomBytes );

    const handleLogin = ( event: any ) => {
      const payload = event.data;
      if( typeof payload === 'string' ) {

        try {
          const message = JSON.parse( payload );

          if( message.messageId === messageId ) {
            window.removeEventListener( 'message', handleLogin );

            if( message.data ) {
              resolve( message.data );
            } else {
              reject( 'Nothing returned' );
            }
          }
        } catch (err) {
          reject( 'Invalid JSON' );
        }
      }

      promise = null;
    };

    const checkPopupStatus = () => {

      if( !popupRef || popupRef.closed ) {
        if( promise ) {
          promise = null;
          resolve( null );
        }
      } else {
        setTimeout( checkPopupStatus, 500 );
      }
    }

    window.addEventListener( 'message', handleLogin );

    popupRef = window.open(`${twitterLoginUrl}?campaignKey=${campaignKey}&messageId=${encodeURIComponent(messageId)}`, popupTarget, POPUP_PARAMS);

    setTimeout( checkPopupStatus, 500 );

  });

  return promise;

}
