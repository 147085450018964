import BASE64 from 'crypto-js/enc-base64';
import HMACSHA256 from 'crypto-js/hmac-sha256';
import * as models from 'models/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { isMobile } from 'react-device-detect';
import { IGenericObject } from 'models/base';
export default class Util {
  /**
   * Deferred - create a deferred object to
   */
  public static Deferred(): models.promise.IDeferred {
    let res;
    let rej;

    const def = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as models.promise.IDeferred;

    if (res && rej) {
      def.resolve = res;
      def.reject = rej;
    }

    return def;
  }
}

export function isValid (value: any) {
  return ['undefined', undefined, null,''].indexOf(value) < 0;
}

export const convertToQspString = (params: any) => {
  let result = '';
  for (const key in params) {
    const value = params[key];

    if (isValid(value)) {
      const encodedValue = fixedEncodeUriComponent(String(value));
      result += `${key}=${encodedValue}&`;
    }
  }
  return result.slice(0, -1);
};

export const createHash = (qsp: string, secret: string) => {
  const hash = HMACSHA256(qsp, secret);
  return BASE64.stringify(hash);
};

export const fixedEncodeUriComponent = (str: string) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
};

export const normalizeForUrl = (name: string) => {
  if (!name) return '';
  return name.toLowerCase().replace(/ /g, '_').replace(constants.URL_REGEX, '');
}

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/ig, ($1: any) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const normalizeStyles = function (o: any) {
  if (isObject(o)) {
    const n: {[key: string]: any} = {};

    Object.keys(o)
      .forEach((k) => {

        if (k === 'hover' && !isMobile) {
          const key = ':hover';
          o[key] = o[k];
          k = key;
        }

        if (k === 'active') {
          const key = ':active';
          o[key] = o[k];
          k = key;
        }

        if(o[k] !== '') {
          n[toCamel(k)] = normalizeStyles(o[k]);
        }

      });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return normalizeStyles(i);
    });
  }

  return o;
};

export const convertRegExp = (string: string) => {
  if (!string) return '';

  var regParts = string.match(/^\/(.*?)\/([gim]*)$/);
  if (regParts) {
      // the parsed pattern had delimiters and modifiers. handle them.
      return new RegExp(regParts[1], regParts[2]);
  } else {
      // we got pattern string without delimiters
      return new RegExp(string);
  }
}

export const checkIfTrue = (value: string) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const shuffleArr = (arr: []) => {
  let shuffledArr = [...arr];
  let currentIndex = shuffledArr.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = shuffledArr[currentIndex];
    shuffledArr[currentIndex] = shuffledArr[randomIndex];
    shuffledArr[randomIndex] = temporaryValue;
  }

  return shuffledArr;
};

export const sortArrByProp = (arr: [], field: string, dir: string) => {
  return [...arr].sort(
    (a: models.base.IGenericObject, b: models.base.IGenericObject) => {
      if (dir === 'ascending') {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      } else if (dir === 'descending') {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      }
      return 0;
    }
  );
};

export const twitterShare = (copy: string, tracking: string, link?: string) => {
  const linkOut = link ? `&url=${link}` : '';

  googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, tracking, '');
  const url = `${constants.TWITTER_INTENT_URL}${encodeURIComponent(copy)}${linkOut}`;
  window.open(url, '_blank', 'height=420,width=550');
};

export const insertFonts = (url: string) => {
  if (!url) {
    return;
  }
  const fontLink = document.createElement('link');
  document.head.appendChild(fontLink);
  fontLink.rel = 'stylesheet';
  fontLink.href = url;
}

export const getQueryParamByName = (name: string, url?: string) => {
  const regexS = '[\\?&#]' + name + '=([^&#]*)';
  const regex = new RegExp(regexS);

  const results = regex.exec( url || window.location.search ) ||
    regex.exec( window.location.hash );

  if ( results === null ) { return ''; }
  return decodeURIComponent( results[ 1 ].replace( /\+/g, ' ' ) );
};

export const sanitizePrefixedData = (data: Array<any>, prefix: string) => {
    return data.map(item => {
        Object.keys(item).map(key => {
            if (key.startsWith(`${prefix}_`)) {
                const newKey = key.replace(`${prefix}_`, '');
                item[newKey] = item[key];
            }
        });
        return item;
    });
};

export function deleteCookie(name:string , domain: string) {
  document.cookie = name + `=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const isEmptyObject = (obj: IGenericObject): boolean => {
  return Object.keys(obj).length === 0
}
