import * as models from 'models/index';
import { media, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    closed: {
      textAlign: 'center',
      flexGrow: '1',
      ...styles.globalStyles.container,
      padding: '100px 20px',

      [media.tablet]: {
        ...styles.globalStyles.container.tablet
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop
      }
    },

    headline: {
      ...styles.globalStyles.headlines,
      ...styles.closedStyles.headline,

      [media.tablet]: {
        fontSize: '45px',
        paddingBottom: '10px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.closedStyles.headline.tablet
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.closedStyles.headline.desktop
      }
    },

    description: {
      paddingBottom: '26px',
      ...styles.closedStyles.description,

      [media.tablet]: {
        fontSize: '20px',
        paddingBottom: '19px',
        ...styles.closedStyles.description.tablet
      },

      [media.desktop]: {
        paddingBottom: '44px',
        ...styles.closedStyles.description.desktop
      }
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      [children('iframe')]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
      },

      [media.desktop]: {
        width: '640px',
        height: '360px',
        paddingBottom: '0',
        margin: 'auto'
      }
    }
  });
