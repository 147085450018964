import * as React from 'react';
import { css } from 'aphrodite/no-important';
import parse from 'html-react-parser';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { style } from './style';

class Footer extends React.Component<models.store.IAppProps> {
  private styles: any;

  render() {
    const { footer, global } = this.props.stylesData;

    this.styles = style({
      globalStyles: global,
      footerStyles: footer,
      settings: { ...footer.settings }
    });

    return (
      <footer className={css(this.styles.footer)} role='contentinfo'>
        <p className={css(this.styles.disclaimer)}>{parse(this.props.copyData.text.footer.disclaimer.copy)}</p>
        <div className={css(this.styles.container)}>
          {this._getLinksList()}
        </div>
      </footer>
    );
  }

  private _getLinksList = () => {
    return Object.keys(this.props.copyData.text.footer.links).filter(category => {
      return category.substring(0, 5) === 'link_';
    }).map((footerLink, i) => {
        const { copy, link } = this.props.copyData.text.footer.links[footerLink];

        return link && (
          <React.Fragment key={link}>
            <a href={link}
              className={css(this.styles.footer_link)}
              rel="noopener noreferrer"
              target="_blank">
              { copy }
            </a>
          </React.Fragment>
          );
      });
  };
}

export default Connect(Footer);
