import * as models from 'models/index';
import * as React from 'react';
import * as api from 'store/api';
import * as auth from 'store/auth';
import * as cms from 'store/cms';
import * as global from 'store/global';
import * as grid from 'store/grid';
import * as loading from 'store/loading';
import * as login from 'store/login';
import * as modal from 'store/modal';
import * as vote from 'store/vote';
import * as constants from 'util/constants';

const { Consumer, Provider } = React.createContext(
  {} as models.store.IAppProps
);

class AppStateProvider extends React.Component {
  public state: models.store.IAppProps = {
    globalProps: {
      category: {},
      contestants: [],
      contestantId: '',
      userData: auth.initialUserData
    },

    globalFn: {
      setContestantId: global.setContestantId.bind(this),
      setPym: global.setPym.bind(this),
      scrollToTop: global.scrollToTop.bind(this),
      storePosition: global.storePosition.bind(this),
      scrollToStoredPosition: global.scrollToStoredPosition.bind(this)
    },

    apiFn: {
      getUserPayload: api.getUserPayload.bind(this),
      getHistoryPayload: api.getHistoryPayload.bind(this),
      getDefaultPayload: api.getDefaultPayload.bind(this)
    },

    authFn: {
      login: auth.login.bind(this),
      logout: auth.logout.bind(this),
      submitLogin: auth.submitLogin.bind(this),
      submitRegistration: auth.submitRegistration.bind(this)
    },

    cmsData: {},

    copyData: {},

    contestantsData: {},

    cmsFn: {
      storeCmsData: cms.storeCmsData.bind(this)
    },

    isAppReady: false,

    loadingFn: {
      setDescription: loading.setDescription.bind(this),
      setTitle: loading.setTitle.bind(this)
    },

    loadingProps: {
      description: '',
      title: '',
      timeout: 0
    },

    loginFn: {
      setFbError: login.setFbError.bind(this)
    },

    loginProps: {
      fbError: ''
    },

    match: {},

    modalFn: {
      closeModal: modal.closeModal.bind(this),
      openModal: modal.openModal.bind(this)
    },

    modalProps: {
      type: '',
      redirectToVote: false
    },

    gridFn: {
      toggleSort: grid.toggleSort.bind(this),
      createContestantList: grid.createContestantList.bind(this),
      handleParams: grid.handleParams.bind(this),
      getContestantById: grid.getContestantById.bind(this),
      displayContestantModal: grid.displayContestantModal.bind(this)
    },

    gridProps: {
      sortingMethod: constants.ALPHABETICAL,
      sortingDirection: constants.ASCENDING,
    },

    isCategoryVote: false,

    isMultiVote: false,

    pym: null,

    stylesData: {},

    voteFn: {
      submitVote: vote.submitVote.bind(this),
      handleVoteSuccess: vote.handleVoteSuccess.bind(this),
      handleVoteError: vote.handleVoteError.bind(this),
      getVoteHistory: vote.getVoteHistory.bind(this),
      updateVoteHistory: vote.updateVoteHistory.bind(this),
      getContestantVotes: vote.getContestantVotes.bind(this),
      getCategoryVotes: vote.getCategoryVotes.bind(this)
    },

    voteHistory: {}
  };

  public render(): React.ReactNode {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const Connect = (Component: typeof React.Component | ((props: any) => any) ) => {
  return (props: models.base.IGenericObject) => {
    return <Consumer>{data => <Component {...data} {...props} />}</Consumer>;
  };
};

export { Connect, AppStateProvider };
