import { StyleSheet } from 'aphrodite/no-important';
import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-variables';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  login: {
    padding: '25px 45px 35px',
    ...styles.loginStyles.root,

    [children('> *')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    },
    [media.tablet]: {
      padding: '55px 45px',
      ...styles.loginStyles.root.tablet,
    },
    [media.desktop]: {
      padding: '55px 45px',
      ...styles.loginStyles.root.desktop,
    }
  },

  loginForm: {
    display: 'grid',
    gridGap: 30,
    gridTemplateAreas: '"inputs" "optins" "methods"',
    ...styles.loginStyles.form
  },

  buttonsGroup: {
    width: '100%',
    ...styles.buttonStyles.container,

    [media.tablet]: {
      ...styles.buttonStyles.container.tablet
    },

    [media.desktop]: {
      ...styles.buttonStyles.container.desktop
    }
  },

  headline: {
    textAlign: 'center',
    paddingBottom: 20,
    ...styles.globalStyles.headlines,
    ...styles.loginStyles.headline,

    [media.tablet]: {
      ...styles.globalStyles.headlines.tablet,
      ...styles.loginStyles.headline.tablet
    },

    [media.desktop]: {
      ...styles.globalStyles.headlines.desktop,
      ...styles.loginStyles.headline.desktop
    }
  },

  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    padding: '8px',
    width: '100%',
    minHeight: 50,
    minWidth: 120,

    [children('span')]: {
      padding: 0
    },
  },

  email_button: {
    ...styles.buttonStyles.email,

    [media.tablet]: {
      ...styles.buttonStyles.email.tablet,
    },

    [media.desktop]: {
      ...styles.buttonStyles.email.desktop,
    }
  },

  facebook_button: {
    lineHeight: '1',
    ...styles.buttonStyles.facebook,

    [children('svg')]: {
      fill: '#fff',
      height: '20px',
      transform: 'translateY(1px)'
    },

    [children('> span')]: {
      display: 'inline-block',
      marginRight: '15px',
      verticalAlign: 'text-top'
    },

    ':hover': {
      background: darkenColor(styles.buttonStyles.facebook.background)
    },

    ':focus': {
      background: darkenColor(styles.buttonStyles.facebook.background)
    }
  },

  twitter_button: {
    backgroundColor: '#1ea1f2',
    color: '#fff',
    ...styles.buttonStyles.twitter,

    [children('svg')]: {
      fill: '#fff',
      height: 20,
      width: 25,
      transform: 'translateY(2px)'
    },

    [children('> span')]: {
      display: 'inline-block',
      marginRight: 15,
      marginLeft: -15,
      verticalAlign: 'text-top'
    },
  },

  divider: {
    display: 'flex',
    alignItems: 'center',
    ...styles.loginStyles.divider.container
  },

  divider_line: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#ccc',
    padding: 0,
    margin: 0,
    ...styles.loginStyles.divider.line
  },

  divider_text: {
    padding: '0 15px',
    fontWeight: 'bold',
    fontSize: '16px',
    ...styles.loginStyles.divider.text
  },

  formGroup: {
    position: 'relative',
    textAlign: 'left',
  },

  input: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    fontSize: '16px',
    height: 45,
    padding: '0 10px',
    width: '100%',
    ...styles.loginStyles.input,

    [media.tablet]: {
      ...styles.loginStyles.input.tablet
    },

    [media.desktop]: {
      ...styles.loginStyles.input.desktop
    }
  },

  label: {
    ...styles.loginStyles.label,

    [media.tablet]: {
      ...styles.loginStyles.label.tablet
    },

    [media.desktop]: {
      ...styles.loginStyles.label.desktop
    }
  },

  checkbox: {
    background: 'linear-gradient(#D7D7D7, #FFF)',
    border: '1px solid #000',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    position: 'absolute',
    top: '1px',
    left: '0px',
    ...styles.loginStyles.terms.checkbox.unchecked
  },

  checkboxInput: {
    position: 'absolute',
    visibility: 'hidden',

    ':checked + label': {
      background: '#053555',
      ...styles.loginStyles.terms.checkbox.checked
    },

    ':checked + label:after': {
      content: "''",
      position: 'absolute',
      border: '1px solid #fff',
      borderTop: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      top: '4px',
      left: '3px',
      width: '8px',
      height: '3px',
      ...styles.loginStyles.terms.checkbox.checkmark
    }
  },

  checkboxLabel: {
    cursor: 'pointer',
    paddingLeft: '25px',
    minHeight: '16px',
    display: 'block',
    ...styles.loginStyles.terms.optIn,
    
    [children('a')]: {
      ...styles.loginStyles.terms.optInLink
    },

    [media.tablet]: {
      ...styles.loginStyles.terms.optIn,

    },

    [media.desktop]: {

    }
  },

  hasError: {
    borderColor: '#C70000'
  },

  errorMessage: {
    color: '#E34530',
    fontSize: '13px',
    marginTop: 5,
    textAlign: 'left',
    ...styles.loginStyles.error,

    [media.tablet]: {
      ...styles.loginStyles.error.tablet
    },

    [media.desktop]: {
      ...styles.loginStyles.error.desktop
    }
  },

  email_cta: {
    ...styles.loginStyles.emailCta,

    [media.tablet]: {
      ...styles.loginStyles.emailCta.tablet
    },

    [media.desktop]: {
      ...styles.loginStyles.emailCta.desktop
    }
  },

  // Areas
  formSection: {
    display: 'grid',
    gridGap: 20
  },

  optinsContainer: {
    gridArea: 'optins'
  },

  methodsContainer: {
    gridArea: 'methods'
  },

  globalInputsContainer: {
    gridArea: 'inputs'
  },

  registerCta: {
    color: '#FFF',
    marginTop: 10,
    fontSize: 12,
    ...styles.loginStyles.registerCta,
    
    [media.tablet]: {
      ...styles.loginStyles.registerCta.tablet
    },

    [media.desktop]: {
      ...styles.loginStyles.registerCta.desktop
    }

  },
  registerCtaButton: {
    color: '#c7d417',
    fontSize: 'inherit',
    appearance: 'none',
    display: 'inline',
    textDecoration: 'underline',
    ...styles.buttonStyles.register
  }
});
