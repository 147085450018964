// Ad Units
export const AD_UNITS = {
  LEADERBOARD: 'leaderboard',
  LEADERBOARD_BOTTOM: 'leaderboard_bottom',
  MOBILE_LEADERBOARD_BOTTOM: 'mobile_leaderboard_bottom',
  MOBILE_LEADERBOARD: 'mobile_leaderboard',
  MOBILE_RECTANGLE: 'mobile_rectangle',
  RECTANGLE: 'rectangle',
  SQUARE: 'square',
  MODAL: 'modal'
};

// App Settings
export const DEFAULT_WID = '8100302955010076';
export const VERSION_CHECK = 'TbqQ5YPbFP08NjBqImbX';

// Auth
export const AUTH_METHODS = {
  EMAIL: 'email',
  FACEBOOK: 'fb',
  TWITTER: 'tw'
};

// API
export const ACTION_TYPES = {
  GET: 'get',
  VOTE: 'vote',
  LOGIN: 'login',
  REGISTER: 'register'
}

// Auth
export const AUTH_LOCALSTORAGE_LABEL = `${DEFAULT_WID}_Auth`;

// Auth Facebook
export const FACEBOOK_AUTH_TYPES = {
  REAUTHENTICATE: 'reauthenticate',
  REAUTHORIZE: 'reauthorize',
  REREQUEST: 'rerequest'
}

//Column Maximum Values
export const COLUMNS = {
  DESKTOP: 6,
  TABLET: 4,
  MOBILE: 2
}

// Countdown
export const SECOND = 1;
export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;

// Endpoints
export const API_CMS = 'https://widgetstate.votenow.tv/v1/state/';
export const API_GEO = '/api/getregion.json';
export const CONNECT_ENDPOINT = 'https://voteapi.votenow.tv/s2/vote';
export const FACEBOOK_SHARE_PATH = '/contestant';
export const TWITTER_INTENT_URL = 'https://twitter.com/intent/tweet?text=';

// Polling Rate
export const MIN_POLLING_RATE = 3000;

// Google Analytics
export const GA_PAGES = {
  GEO: 'Out of Geo',
  GRID: 'Category',
  CLOSED: 'Closed',
  CATEGORIES: 'Categories',
  CONTESTANT: 'Contestant'
};
export const GA_CATEGORIES = {
  BUTTON_CLICK: 'Button Clicked',
  LINK_CLICK: 'Link Clicked',
  USER_LANGUAGE: 'User Language',
  USER_NATION: 'User Nation'
};
export const GA_EVENTS = {
  EMAIL_LOGIN: 'Email Login',
  FACEBOOK_LOGIN: 'Facebook Login',
  FACEBOOK_SHARE: 'Facebook Share',
  LOGOUT: 'Logout',
  SELECT_CONTESTANT: 'Select Contestant',
  SUBMIT_VOTE: 'Submit Vote',
  TWITTER_SHARE: 'Twitter Share',
  TWITTER_VOTE: 'Twitter Vote',
  DROPDOWN_TOGGLE: 'Dropdown Toggle',
  NAVIGATION: 'Navigation'
};

// Images
export const IMG_LOADING = '/images/loading.svg';

// Keyboard
export const KEYS = {
  DOWN: 40,
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  UP: 38
};

// Modal
export const MODAL_TYPES = {
  confirmation: 'confirmation',
  errorGeneric: 'errorGeneric',
  errorOverlimit: 'errorOverlimit',
  errorWindow: 'errorWindow',
  login: 'login',
  vote: 'vote'
}

// Response Codes
export const RESPONSE_CODES = {
  GENERAL_INVALID: '16',
  VALID: '20',
  OVERLIMIT: '21',
  OUT_OF_WINDOW: '40',
  VALID_LOGIN: '31',
  VALID_REGISTER: '30'
};

// Routing
export const URL_REGEX = /[^0-9A-Za-z$\-_.+!*'(),]/g;

// Navigation
export const NAVIGATE = {
  NEXT: 'next',
  PREV: 'prev',
  ALL: 'all'
}

// Sorting
export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';
export const RANDOM = 'random';
export const RANDOMIZE = 'randomize';
export const RANDOMIZED = 'randomized';
export const ALPHA = 'alpha';
export const ALPHABETICAL = 'alphabetical';
export const SORT_BY = {
  ID: 'id',
  NAME: 'name',
  DESCRIPTION_1: 'description_1',
  DESCRIPTION_2: 'description_2',
  DESCRIPTION_3: 'description_3',
  HEADLINE: 'headline',
  IMAGE: 'image',
  VIDEO: 'video',
  LINK_URL: 'link_url',
  IS_ELIMINATED: 'is_eliminated',
  ORDER: 'order',
};
