import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import * as fbHelpers from 'util/fb-helpers';
import { FacebookIcon, TwitterIcon } from 'util/icons';
import { style } from './style';

interface IShareProps {
  displayFacebook: boolean;
  displayTwitter: boolean;
}

type Props = IShareProps & models.store.IAppProps;

class Share extends React.Component<Props> {
  public render(): React.ReactNode {
    const { settings } = this.props.cmsData.text.share;
    const copy = this.props.copyData.text.share;
    const { contestantId } = this.props.globalProps;
    const contestant = this.props.gridFn.getContestantById(contestantId);
    const styles = style({
      shareStyles: this.props.stylesData.thankYou.share
    });
    const { displayFacebook, displayTwitter } = this.props;

    const tweet = copy.twitter.copy
      .replace(/{{NAME}}/gi, contestant.name.trim())
      .replace(/{{HASHTAG}}/gi, contestant.hashtag.trim());
    
    const twitterShare = () => {
      helpers.twitterShare(tweet, constants.GA_EVENTS.TWITTER_SHARE, copy.twitter.link)
    }
    
    const facebookShare = () => {
      googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.FACEBOOK_SHARE,'');
  
      const { copy, url = window.location.href } = this.props.copyData.text.share.facebook;
      const { settings } = this.props.cmsData.text.share;
  
      if (!helpers.checkIfTrue(settings.share_contestant)) {
        const u = encodeURIComponent(url);
        const quote = copy ? `&quote=${encodeURIComponent(copy)}` : '';
  
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${u}${quote}`,
            'popup',
            'width=600, height=400, scrollbars=no'
        );
      } else {
        const { category, contestantId } = this.props.globalProps;
  
        const url = `${window.location.origin}/share`;
  
        fbHelpers.share(url, {
          contestant: contestantId,
          ...(this.props.isCategoryVote? {
            category: category.id
          }:{})
        });
      }
    }

    return (
      <div className={css(styles.share)}>
        { (displayFacebook || displayTwitter) &&
          <h3 className={css(styles.cta)}> { copy.cta } </h3> }

        { displayTwitter &&
          <button
            className={`${css(styles.social_button)} ${css(styles.social_twitter)}`}
            onClick={twitterShare}
            aria-label={copy.twitter.icon_accessibility}>
            <TwitterIcon />
          </button>
        }

        { displayFacebook &&
          <button
            className={`${css(styles.social_button)} ${css(styles.social_facebook)}`}
            onClick={facebookShare}
            aria-label={copy.facebook.icon_accessibility}>
            <FacebookIcon />
          </button>
        }
      </div>
    );
  }
}

export default Connect(Share);
