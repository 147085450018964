export class DOMUtil {
  public static isIframed(): boolean {
    return window.self !== window.top;
  }

  public static isLocalStorageAvailable(): boolean {
    const randKey = Math.round(Math.random() * 100000000) + '';

    try {
      localStorage.setItem(randKey, randKey);
      localStorage.getItem(randKey);
      localStorage.removeItem(randKey);
      return true;
    } catch (e) {
      return false;
    }
  }
}
