import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import * as models from 'models/index';
import { Connect } from 'store/index';
import { RightArrowIcon, LeftArrowIcon, PlusIcon, MinusIcon } from 'util/icons';
import Button from 'components/button';
import Video from 'components/video';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import * as googleHelpers from 'util/google-helpers';
import history from 'util/history';
import { css } from 'aphrodite/no-important';
import { style } from './style';
import { useVoteAttempt } from 'store/vote';

const BUTTON_TYPES = {
  SUBMIT: 'submit',
  PLUS: 'plus',
  MINUS: 'minus'
}

type VoteProps = models.store.IAppProps & models.vote.IVoteSwap & models.global.IGenericProps;

function Vote(props: VoteProps) {
  const { isMultiVote, cmsData, globalProps, gridFn, copyData, stylesData, voteHistory } = props;
  const { vote_settings: voteSettings } = cmsData.text;
  const { gtm_group: gtmGroup } = props.cmsData.settings; 

  const MAX_CONTESTANT_VOTES = parseInt(voteSettings.max_votes_per_contestant, 10);
  const MAX_TOTAL_VOTES = parseInt(voteSettings.max_votes_per_category, 10);

  const defaultVoteCount = isMultiVote ? 0 : 1;

  const { isUserValid } = useVoteAttempt(globalProps.userData);

  const { contestantId } = globalProps;
  const contestant = gridFn.getContestantById(contestantId);

  const contestantVotes = props.voteFn.getContestantVotes(contestantId);
  const totalContestants = props.globalProps.contestants.length;
  const [ voteCount, setVoteCount ] = useState(contestantVotes);

  const copy = copyData.text.confirmation;

  const isEliminated = helpers.checkIfTrue(contestant.is_eliminated);
  const isWindowOpen = helpers.checkIfTrue(cmsData.settings.window_status);

  const styles = style({
    globalStyles: stylesData.global,
    voteStyles: stylesData.confirmation
  });

  const _scrollTargetIndex = useCallback((direction: string) => {
    const contestants = props.globalProps.contestants;
    let index = contestants.findIndex((c: any) => contestantId === c.id );

    index += (direction === 'left' ? -1 : 1);
    index = (index + totalContestants) % totalContestants;

    const nextContestant = contestants[index];

    props.globalFn.setContestantId(nextContestant.id);

  }, [contestantId, props.globalProps, totalContestants, props.globalFn]);

  // Set vote counts if the contestant changes
  useEffect(() => {
    const previouslySubmittedVoteCount = props.voteFn.getContestantVotes(contestantId);

    // If it's not a multivote, we automatically increment
    // the allocated votes by the default vote (usually 1)
    const voteCount = isMultiVote
      ? previouslySubmittedVoteCount
      : previouslySubmittedVoteCount + defaultVoteCount;

    setVoteCount(voteCount);
  }, [contestantId, isMultiVote]);
 
  // Update url when contestantId changes
  useEffect(() => {
    const { contestantId } = props.globalProps;
    const contestant = props.gridFn.getContestantById(contestantId);
    const contestantName = helpers.normalizeForUrl(contestant.name);
    const categoryName = helpers.normalizeForUrl(props.globalProps.category.name);
    const pathname = props.isCategoryVote? `/${categoryName}/${contestantName}` : `/${contestantName}`;

    googleHelpers.trackGooglePage(pathname);

    history.push({
      pathname,
      search: history.location.search,
    });

    return () => {
      const pathname = props.isCategoryVote? `/${categoryName}` : `/`;

      history.push({
        pathname,
        search: history.location.search,
      });
    };
  }, [props.globalProps, props.gridFn, props.isCategoryVote]);


  // Handle left/right arrow keypresses to change contestant
  useEffect(() => {
    function _handleKeyDown(e: any) {
      switch (e.keyCode) {
        case constants.KEYS.LEFT:
          _scrollTargetIndex('left');
          break;
  
        case constants.KEYS.RIGHT:
          _scrollTargetIndex('right');
          break;
  
        default:
          break;
      }
    }

    document.addEventListener('keydown', _handleKeyDown);

    return () => {
      document.removeEventListener('keydown', _handleKeyDown);
    }
  }, [_scrollTargetIndex]);


  const externalLinkLabel = copy.link_accessibility?.replace('{{NAME}}', contestant.name);

  return (
      <section className={css(styles.vote)} aria-label='Confirm your vote' aria-roledescription='carousel'>
      <div className={css(styles.media_container)}>
        { contestant.image && !contestant.video &&
            <img src={contestant.image} alt={contestant.name} /> }

        { contestant.video &&
          <div className={css(styles.video)}>
            <div className={css(styles.video_wrapper)}>
              <Video url={contestant.video} />
            </div>
          </div> }
      </div>

      <div className={css(styles.info_container)}>
  

        { contestant.nominee_headline &&
          <p className={css(styles.headline)}> {contestant.nominee_headline} </p> }

        <div aria-live="polite" aria-atomic="true" role="region">
          { contestant.name &&
            <h1 className={css(styles.name)} dangerouslySetInnerHTML={{ __html: contestant.name }}/> }
        </div>

        { contestant.description_1 &&
          <p className={css(styles.description_1)}>
            {contestant.description_1}
          </p> }

        { contestant.description_2 &&
          <p className={css(styles.description_2)}>
            {contestant.description_2}
          </p> }

        { contestant.description_3 &&
          <p className={css(styles.description_3)}>
            {contestant.description_3}
          </p> }
      </div>

      <div className={css(styles.cta_container)}>

        { contestant.link_url && copy.link &&
          <a href={contestant.link_url} target="_blank" rel="noreferrer" className={css(styles.link)} aria-label={externalLinkLabel}> { copy.link } </a> }

        {isMultiVote && isWindowOpen && !isEliminated && (
        <div className={css(styles.vote_buttons_wrapper)}>
          <button
            className={css(styles.vote_button, styles.vote_button_minus)}
            onClick={() => _handleVotesUpdate(-1)}
            aria-disabled={isDisabled(BUTTON_TYPES.MINUS)}
            aria-label='subtract vote'
          >
            <MinusIcon />
          </button>

          <p className={css(styles.vote_counter)}
            aria-readonly='true'
            role='presentation'
            aria-hidden='true'>
            {voteCount}
          </p>

          <button
            className={css(styles.vote_button, styles.vote_button_plus)}
            onClick={() => _handleVotesUpdate(1)}
            aria-disabled={isDisabled(BUTTON_TYPES.PLUS)}
            aria-label='add vote'
          >
            <PlusIcon />
          </button>
        </div>
      )}
      { !contestant.nominee_headline && copy.universal_headline && helpers.checkIfTrue(props.cmsData.settings.window_status) &&
          <p className={css(styles.headline)}> {copy.universal_headline} </p> }
      { isWindowOpen && !isEliminated && 
        <>
        <Button
          aria-label='Submit Votes'
          buttonData={copy.buttons.vote}
          buttonStyles={props.stylesData.confirmation.buttons.vote}
          options={{ globalStyles: props.stylesData.global.buttons }}
          onClick={() => handleSubmit()}
          isDisabled={isDisabled(BUTTON_TYPES.SUBMIT)}
        /> 

        { isOverlimitVote() && copy.overlimit_message &&
          <p className={css(styles.vote_counter)}> { copy.overlimit_message } </p> }
        </>
      }

      </div>

      {props.children}

      { totalContestants > 1 && (
        <>
          <button
            aria-label='Previous contestant'
            className={css(styles.nav_arrow, styles.nav_prev)}
            onClick={() => _scrollTargetIndex('left')}
          >
            <LeftArrowIcon />
          </button>

          <button
            aria-label='Next contestant'
            className={css(styles.nav_arrow, styles.nav_next)}
            onClick={() => _scrollTargetIndex('right')}
          >
            <RightArrowIcon />
          </button>
        </>
      )}

    </section>
  );

  function handleSubmit() {
    // aria-disabled won't stop click events from being fired, so need to check here
    if (isDisabled(BUTTON_TYPES.SUBMIT)) {
      return false;
    }

    if (!isUserValid) {
      props.modalFn.openModal(constants.MODAL_TYPES.login, true);
    } else {
      props.voteFn.submitVote(voteCount);
      googleHelpers.trackConversionEvent(gtmGroup); // ITHF-1 - Track conversions

    }
  }


  function _handleVotesUpdate(value: number) {
    const newVotes = voteCount + value;
    const isValidVote = _isValidVote(newVotes);
    const { multivote_settings } = props.cmsData.text.vote_settings;

    const allowIncrement = helpers.checkIfTrue(multivote_settings.allow_plus);
    const allowDecrement = helpers.checkIfTrue(multivote_settings.allow_minus);
    const disableIncrementButton =
      value > 0 && !allowIncrement && newVotes > contestantVotes;
    const disableDecrementButton =
      value < 0 && !allowDecrement && newVotes < contestantVotes;

    if (disableIncrementButton || disableDecrementButton) {
      return;
    }

    if (!isUserValid) {
      return props.modalFn.openModal(constants.MODAL_TYPES.login, true);
    }

    if (!isValidVote) {
      return;
    }

    setVoteCount(newVotes);
  }

  function isOverlimitVote(): boolean {
    const votesRemaining = getTotalVotesRemaining();
    // voteCount is allocated on component mount. All vote calculations use the allocated voteCount 
    // Here votesRemaining can be equal to 0 because it's taking into account voteCount 
    const hasTotalVotesRemaining = votesRemaining >= 0;
    const hasContestantVotesRemaining = !props.isMultiVote?
      voteCount - defaultVoteCount < MAX_CONTESTANT_VOTES : true;

    return !hasTotalVotesRemaining || !hasContestantVotesRemaining;
  }

  function _isValidVote(count: number): boolean {
    const totalVotesRemaining = getTotalVotesRemaining(count);

    if (count > MAX_CONTESTANT_VOTES) {
      return false;
    }
    if (count < 0) {
      return false;
    }
    if (totalVotesRemaining < 0) {
      return false;
    }

    return true;
  }

  function getTotalVotesRemaining(currentVotes = voteCount) {
    const votesAlreadySubmitted = voteHistory.total || 0;

    const unsubmittedVotes = contestantVotes - currentVotes;

    return MAX_TOTAL_VOTES - votesAlreadySubmitted + unsubmittedVotes;
  }

  function isDisabled(button: string) {
    const votesRemaining = getTotalVotesRemaining();

    const { allow_plus: allowPlus, allow_minus: allowMinus } = props.cmsData.text.vote_settings.multivote_settings
    const allowIncrement = helpers.checkIfTrue(allowPlus);
    const allowDecrement = helpers.checkIfTrue(allowMinus);

    const disableIncrementButton =
      !allowIncrement && voteCount >= contestantVotes;
    const disableDecrementButton =
      !allowDecrement && voteCount <= contestantVotes;

    switch (button) {
      case BUTTON_TYPES.SUBMIT:
        const hasChanged = props.isMultiVote? voteCount !== contestantVotes : true;
        const isOverlimit = isOverlimitVote();

        return !hasChanged || isOverlimit;

      case BUTTON_TYPES.PLUS:
        return (
          voteCount === MAX_CONTESTANT_VOTES ||
          votesRemaining <= 0 ||
          disableIncrementButton
        );
      case BUTTON_TYPES.MINUS:
        return voteCount === 0 || disableDecrementButton;
      default:
        return false;
    }
  }

}

export default Connect(Vote);
