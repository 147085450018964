import * as React from 'react';
import { useState, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { Route, Switch, withRouter } from 'react-router-dom';
import { LocalStorage } from 'ttl-localstorage';
import FacebookPixel from 'react-facebook-pixel';

import AdUnit from 'components/ad-unit/index';
import Categories from 'components/categories/index';
import Closed from 'components/closed/index';
import Confirmation from 'components/thanks/index';
import CookieConsent from 'components/cookie-consent';
import Countdown from 'components/countdown/index';
import Dropdown from 'components/dropdown/index';
import FbError from 'components/fb-error/index';
import ErrorMessage from 'components/error-message/index';
import Footer from 'components/footer/index';
import Header from 'components/header/index';
import Loading from 'components/loading/index';
import Login from 'components/login/index';
import Navigation from 'components/navigation/index';
import Modal from 'components/modal/index';
import Grid from 'components/grid/index';
import Share from 'components/share/index';
import User from 'components/user/index';
import Vote from 'components/vote/index';

import * as models from 'models/index';

import { Connect } from 'store/index';

import api from 'util/api';
import * as constants from 'util/constants';
import * as fbHelpers from 'util/fb-helpers';
import * as googleHelpers from 'util/google-helpers';
import { loginWithJwt } from 'util/twitter';
import { checkIfTrue, insertFonts, isEmptyObject } from 'util/helpers';
import { storageFactory } from 'util/storage_helpers';
import useGeo from '@telescope/react-hooks/useGeo';
import useCMS from '@telescope/react-hooks/useCMS';
import { style } from './style';

const localStore = storageFactory(localStorage);
const MIN_POLLING_RATE = 5000;

function App(props: models.store.IAppProps) {

  // Main Widget
  const { sid = '', wid = api.defaults.wid } = api.storage.qsps;
  const [ pollingFrequency, setPollingFrequency ] = useState(MIN_POLLING_RATE);
  const [ widget ] = useCMS<models.api.ICmsData>(wid, { sid, pollingFrequency });

  // Supporting Widgets
  const { copy_sid: copySid = '', copy_wid: copyWid = widget?.text.snapshot_settings.copy_wid,
            contestants_sid: contestantsSid = '', contestants_wid: contestantsWid = widget?.text.snapshot_settings.contestants_wid,
            styles_sid: stylesSid = '', styles_wid: stylesWid = widget?.text.snapshot_settings.styles_wid
    } = api.storage.qsps;

  const [ copyWidget ] = useCMS<models.api.ICmsData>(copyWid, { sid: copySid, pollingFrequency: widget?.text.snapshot_settings.polling_frequency || MIN_POLLING_RATE });
  const [ contestantsWidget ] = useCMS<models.api.ICmsData>(contestantsWid, { sid: contestantsSid, pollingFrequency: widget?.text.snapshot_settings.polling_frequency || MIN_POLLING_RATE });
  const [ stylesWidget ] = useCMS<models.api.ICmsData>(stylesWid, { sid: stylesSid, pollingFrequency: widget?.text.snapshot_settings.polling_frequency || MIN_POLLING_RATE });

  const geo = useGeo(widget?.settings.countries);

  const readLocalStore = async () => {
    const storedUser = localStore.getItem(constants.AUTH_LOCALSTORAGE_LABEL);

    if (!storedUser) {
      return;
    }

    const payload = JSON.parse(storedUser);
    const { method } = payload;

    switch (method) {
      case constants.AUTH_METHODS.EMAIL:
        await props.authFn.login(payload, method);
        break;

      case constants.AUTH_METHODS.TWITTER:
        const { twitter_auth_key, twitter_auth_url } = props.cmsData.settings;

        const twitterPayload = await loginWithJwt(payload.id, twitter_auth_key, twitter_auth_url) as models.auth.ITwitterLoginPayload;
        await props.authFn.login({ ...twitterPayload, ...payload }, method);
        break;

      case constants.AUTH_METHODS.FACEBOOK:
        fbHelpers.checkLoginState()
        .then((res) => props.authFn.login({ ...res, ...payload }, method))
        .catch(() => {
          //Do nothing
        });
        break;
      default:
        // if no valid method is found, remove auth from localstore
        localStore.removeItem(constants.AUTH_LOCALSTORAGE_LABEL);
    }
  };

  /**
   * Handler to be executed when a user accepts cookies. Should be used to initialize any 
   * analytics tracking or set cookies that are not essential to the functionality of the app.
   */
  const handleCookieConsentAccepted = () => {
    initAnalytics();
  }

  /**
   * Handler to be executed when a user declines cookies. Should be used to destroy any initialized
   * analytics trackers. 
   */
  const handleCookieConsentDeclined = () => {
    // Currently a noop. Reserved for future if needed. 
  }

  const initAnalytics = () => {
    googleHelpers.initializeGoogleAnalytics(widget.settings.google_analytics);
    FacebookPixel.init(widget.settings.facebook_pixel_id);
    FacebookPixel.pageView();
    googleHelpers.initGlobalSiteTag(widget.settings.gtm_id);

  }

  // Bootstrap app after data is fetched
  useEffect(() => {
    if (!widget || !copyWidget || !contestantsWidget || !stylesWidget) return;

    async function bootstrap() {
      props.cmsFn.storeCmsData(widget, copyWidget, contestantsWidget, stylesWidget.text);

      // Check if cookie consent is required, and if so, has consent been granted
      const isCookieConsentRequired = checkIfTrue(widget.text.cookie_consent.enabled);
      let isCookieConsentGiven = !isCookieConsentRequired || LocalStorage.get(widget.text.cookie_consent.storage_key);

      // Initialize FB SDK if FB is needed for login or share
      if (checkIfTrue(widget.text.login.settings.display_facebook) ||
          checkIfTrue(widget.text.share.settings.share_contestant)) {
        fbHelpers.initializeFbSdk(widget.social.fb.id);
      }

      await readLocalStore();

      // Analytics
      if (isCookieConsentGiven) {
        initAnalytics();
      }
      googleHelpers.addLinkClickListener();
      
      props.globalFn.setPym();
    } 

    bootstrap();

    return () => {
      googleHelpers.removeLinkClickListener();
    }
    
  }, [widget, copyWidget, contestantsWidget, stylesWidget]);

  // Handle font import after styles data is stored
  useEffect(() => {
    if (props.stylesData.global) {
      insertFonts(props.stylesData.global.font.fontImport);
    }
  }, [props.stylesData])


  if (!props.isAppReady || isEmptyObject(geo.data)) { 
    return <Loading />;
  }

  // Render app
  const { text, settings } = props.cmsData;
  const copy = props.copyData.text;
  const { stylesData } = props;
  const styles = style({
    pageStyles: props.stylesData.app,
    globalStyles: props.stylesData.global,
    modalOpen: props.modalProps.type !== ''
  });
  const gridEnabled = (checkIfTrue(settings.window_status) || (!checkIfTrue(settings.window_status) && checkIfTrue(text.closed.settings.display_grid)));

  const ComposedLogin = (
    <Login SwapLoading={Loading}
      SwapError={FbError} />
  );

  const modalMap: {[key: string]: any} = {
    confirmation: (
      <Confirmation>
        <Share displayFacebook={checkIfTrue(props.cmsData.text.thank_you.settings.display_share.display_facebook)} 
               displayTwitter={checkIfTrue(props.cmsData.text.thank_you.settings.display_share.display_twitter)} />
      </Confirmation>),
    errorGeneric: <ErrorMessage data={copy.errors.generic} styles={stylesData.errors.generic}/>,
    errorOverlimit: <ErrorMessage data={copy.errors.overlimit} styles={stylesData.errors.overlimit}/>,
    errorWindow: <ErrorMessage data={copy.errors.window} styles={stylesData.errors.window}/>,
    login: ComposedLogin,
    vote: <Vote />
  };

  const renderGrid = () => {
    return (
      <>
        { props.isCategoryVote && checkIfTrue(text.grid.settings.display_dropdown) &&
          <Dropdown /> }

        { props.isCategoryVote && checkIfTrue(settings.window_status) &&
          <User /> }

        <Grid>
          { checkIfTrue(text.ads.square.settings.display) &&
          <AdUnit size={constants.AD_UNITS.SQUARE} /> }

        </Grid>

        { props.isCategoryVote && checkIfTrue(text.grid.settings.display_navigation) &&
          <Navigation /> }
      </>
    )
  }

  return (
    <div className={css(styles.page)}>
        <div className={css(styles.app_container)} aria-hidden={props.modalProps.type !== ""}>

          {checkIfTrue(text.ads.leaderboard.settings.display) &&
            <AdUnit size={constants.AD_UNITS.LEADERBOARD} /> }

          {checkIfTrue(text.ads.mobile_leaderboard.settings.display) &&
            <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD} />}

          {checkIfTrue(text.header.settings.display) &&
            <Header>

              { checkIfTrue(text.ads.rectangle.settings.display) &&
                <AdUnit size={constants.AD_UNITS.RECTANGLE} /> }

              { checkIfTrue(text.ads.mobile_rectangle.settings.display) &&
                <AdUnit size={constants.AD_UNITS.MOBILE_RECTANGLE} /> }

            </Header> }

          { geo.inRegion?
            <main className={css(styles.app)} role='main'>

              { !checkIfTrue(settings.window_status) && !gridEnabled &&
                <Closed>
                  {checkIfTrue(text.closed.settings.display_countdown) &&
                    <Countdown key='countdown' />}
                </Closed> }

              { !props.isCategoryVote && checkIfTrue(settings.window_status) &&
                <User /> }

              <Switch>
                <Route exact={true} path='/' render={() => {
                  if (!gridEnabled) { return null; }

                  return (
                    <>
                    { props.isCategoryVote?
                      <Categories>
                        { checkIfTrue(settings.window_status) &&
                        <User /> }
                      </Categories> :

                      renderGrid() } 
                    </>
                  )
                }} />

                <Route path={["/:name/:detail", "/:name"]} render={() => {
                  if (!gridEnabled) { return null; }

                  return renderGrid();
                }} />
              </Switch>

              { checkIfTrue(text.ads.leaderboard_bottom.settings.display) &&
                <AdUnit size={constants.AD_UNITS.LEADERBOARD_BOTTOM} /> }

            { checkIfTrue(text.ads.mobile_leaderboard_bottom.settings.display) &&
              <AdUnit size={constants.AD_UNITS.MOBILE_LEADERBOARD_BOTTOM} /> }
          </main> :
          
          <ErrorMessage data={copy.errors.geo} styles={stylesData.errors.geo} track={constants.GA_PAGES.GEO}/> }

          { checkIfTrue(text.footer.settings.display) &&
            <Footer/> }
        </div>

        { modalMap[props.modalProps.type] && (
          <Modal>
            { modalMap[props.modalProps.type] }

            { checkIfTrue(text.ads.modal.settings.display) &&
            ( props.modalProps.type === constants.MODAL_TYPES.confirmation || 
              props.modalProps.type === constants.MODAL_TYPES.vote ) &&
            <AdUnit size={constants.AD_UNITS.MODAL} /> }
        </Modal>)}
        
        {checkIfTrue(props.cmsData.text.cookie_consent.enabled) && (
        <CookieConsent
          storageKey={`${wid}_${props.cmsData.text.cookie_consent.storage_key}`}
          onAccept={handleCookieConsentAccepted}
          onDecline={handleCookieConsentDeclined}
          acceptButtonLabel={copy.cookie_consent.accept_button_text}
          declineButtonLabel={copy.cookie_consent.decline_button_text}
          showDeclineButton={checkIfTrue(
            props.cmsData.text.cookie_consent.allow_decline_consent
          )}
          expires={parseInt(props.cmsData.text.cookie_consent.consent_expiry_in_days, 10)}
          layout={props.cmsData.text.cookie_consent.layout}
          styleOverrides={props.stylesData.cookieConsent}
        >
          <span
            dangerouslySetInnerHTML={{ __html: copy.cookie_consent.content }}
          />
        </CookieConsent>
      )}

      </div>
  )
}

export default withRouter(Connect(App));