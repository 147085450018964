import * as models from 'models/index';
import { media, darkenColor, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) =>
  StyleSheet.create({
    button: {
      color: '#808080',
      display: 'inline',
      fontSize: '16px',
      backgroundColor: 'transparent',
      margin: 0,
      ...styles.userStyles.link,

      [media.tablet]: {
        ...styles.userStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.userStyles.link.desktop,
      },

      ':hover': {
        color: styles.userStyles.link? darkenColor(styles.userStyles.link.color) : ''
      },

      ':focus': {
        color: styles.userStyles.link? darkenColor(styles.userStyles.link.color) : ''
      }
    },

    user: {
      width: '100%',
      textAlign: 'right',
      padding: '5px 0px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      ...styles.userStyles.general,

      [media.tablet]: {
        ...styles.userStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.userStyles.general.desktop,
      }
    },

    greeting_message: {
      display: 'grid',
      padding: 0,
      gridTemplateColumns: '1fr auto 1fr',
      whiteSpace: 'nowrap',
      ...styles.userStyles.greetingMessage,

      [children('> span')]: {
        textOverflow: 'ellipsis',
        whiteDpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: 4
      },

      [media.tablet]: {
        display: 'inline-block',
        marginRight: 15,
        ...styles.userStyles.greetingMessage.tablet,
      },

      [media.desktop]: {
        ...styles.userStyles.greetingMessage.desktop,
      }
    }
  });
