import * as models from 'models/index';
import { media, children } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  dropdown: {
    backgroundColor: '#fff',
    position: 'relative',
    marginBottom: 10,
    zIndex: 1,

    [children('a')]: {
      color: 'inherit',
      cursor: 'inherit',
      textDecoration: 'none',
      padding: 0,
      margin: 0
    }
  },

  button: {
    width: '100%',
    padding: 15,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',

    [children('> *')]: {
      padding: 0
    },

    [children('svg')]: {
      fill: 'inherit',
      height: 'auto',
      width: 15,
      marginLeft: 5,
      transform: styles.isOpen? 'rotate(180deg)' : 'none'
    },

    ...styles.dropdownStyles.button,

    [media.tablet]: {

      ...styles.dropdownStyles.button.tablet
    },

    [media.desktop]: {
      ...styles.dropdownStyles.button.desktop
    }
  },

  menu: {
    position: 'absolute',
    padding: '20px 20px 0',
    width: '100%',
    zIndex: 2,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    ...styles.dropdownStyles.menu,

    [media.tablet]: {
      flexDirection: 'row',
      padding: '40px 20px 20px',
      ...styles.dropdownStyles.menu.tablet
    },

    [media.desktop]: {
      ...styles.dropdownStyles.menu.desktop
    }
  },

  link: {
    cursor: 'pointer',
    marginBottom: 20,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...styles.dropdownStyles.links,

    [media.tablet]: {
      minWidth: '33.33%',
      marginBottom: 25,
      ...styles.dropdownStyles.links.tablet
    },

    [media.desktop]: {
      ...styles.dropdownStyles.links.desktop
    }
  },

  selected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...styles.dropdownStyles.selected,

    [media.tablet]: {
      minWidth: '33.33%',
      ...styles.dropdownStyles.selected.tablet
    },

    [media.desktop]: {
      ...styles.dropdownStyles.selected.desktop
    }
  },

  disabled: {
    cursor: 'default',
    ...styles.dropdownStyles.disabled,

    [media.tablet]: {

      ...styles.dropdownStyles.disabled.tablet
    },

    [media.desktop]: {
      ...styles.dropdownStyles.disabled.desktop
    }
  }
});
