import * as ReactGA from 'react-ga';
import * as constants from 'util/constants';
import { DOMUtil } from './dom';

let _isGoogleInitialized = false;
let _isTargetingAllowed = false;
const gtagScriptId = 'ga-gtag';
interface GoogleAnalyticsInitOptions {
  isTargetingAllowed?: boolean;
}
interface IGooglePageView {
  type: string;
  payload: string;
}
interface IGoogleEvent {
  type: string;
  payload: { category: string; action: string; label: string };
}

interface IGoogleModalView {
  type: string;
  payload: string;
}

let googleQueue: Array<IGoogleEvent | IGooglePageView | IGoogleModalView> = [];

const GA_LOCAL_STORAGE_KEY = 'ga:clientId';
const _trackerNames: string[] = [];

export const initializeGoogleAnalytics = (gaId: string, options: GoogleAnalyticsInitOptions = {
  isTargetingAllowed: true
}) => {

  _isTargetingAllowed = options.isTargetingAllowed?? true;

  if (gaId && !_isGoogleInitialized) {
    const gaIds = gaId.split(',');
    const trackers: ReactGA.Tracker[] = [];
    const useLocalStorage = shouldUseLocalStorage();
    gaIds.forEach((id, index) => {
      trackers.push({
        trackingId: id,
        gaOptions: {
          name: `tracker_${index}`,
          ...(useLocalStorage && {
            storage: 'none',
            clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY) ?? undefined,
          }),
        },
      });
      _trackerNames.push(`tracker_${index}`);
    });

    ReactGA.initialize(trackers);

    // If using localStorage, set clientId
    ReactGA.ga(() => {
      if (useLocalStorage) {
        const clientID = window.ga.getByName(_trackerNames[0]).get('clientId');
        localStorage.setItem(GA_LOCAL_STORAGE_KEY, clientID);
      }
    });

    _isGoogleInitialized = true;
  }

  if (googleQueue.length > 0) {
    googleQueue.forEach((call: IGooglePageView | IGoogleEvent) => {
      switch (call.type) {
        case 'page':
          trackGooglePage((call as IGooglePageView).payload);
          break;
        case 'event':
          const { category, action, label } = (call as IGoogleEvent).payload;
          trackGoogleEvent(category, action, label);
          break;
        case 'modal':
          trackGoogleModal((call as IGoogleModalView).payload);
      }
    });

    googleQueue = [];
  }
};

export const isInitialized = () => {
  return _isGoogleInitialized;
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string = ''
) => {

  if (!_isTargetingAllowed) return;

  if (!_isGoogleInitialized) { 
    googleQueue.push({
      payload: { category, action, label },
      type: 'event',
    });

    return;
  }

  if (_trackerNames.length) {
    ReactGA.event({ category: category, action: action, label: label }, _trackerNames);
  } else {
    ReactGA.event({ category, action, label });
  }

};

export const trackGooglePage = (page: string) => {
  if (!_isTargetingAllowed) return;

  if (!_isGoogleInitialized) { 
    googleQueue.push({
      payload: page,
      type: 'page',
    });

    return;
  }

  if (_trackerNames.length) {
    ReactGA.pageview(page, _trackerNames);
  } else {
    ReactGA.pageview(page);
  }

};

export const trackGoogleModal = (modal: string) => {
  if (!_isTargetingAllowed) return;

  if (!_isGoogleInitialized) { 
    googleQueue.push({
      payload: modal,
      type: 'modal',
    });

    return;
  }

  if (_trackerNames.length) {
    ReactGA.modalview(modal, _trackerNames);
  } else {
    ReactGA.modalview(modal);
  }

};

export const addLinkClickListener = () => {
  document.addEventListener('click', checkLinkClick, false);
};

export const removeLinkClickListener = () => {
  document.removeEventListener('click', checkLinkClick, false);
};

export const checkLinkClick = (evt: any) => {
  if (!_isTargetingAllowed) return;

  if(evt.target.tagName.toLowerCase() === 'a') {
      const category = constants.GA_CATEGORIES.LINK_CLICK;
      const action = evt.target.href;
      const label = evt.target.textContent;
      trackGoogleEvent(category,action,label);
  }
}

/**
 * Check if GA should use localStorage to store the clientID
 * @returns true if the application is inside an iframe and local storage is enabled, false otherwise.
 */
 const shouldUseLocalStorage = () => {
  return DOMUtil.isIframed() && DOMUtil.isLocalStorageAvailable();
 };

export const initGlobalSiteTag = (trackingId: string, additionalConfigInfo = {}) => {
  if (document.getElementById(gtagScriptId)) return;
   
  const {head} = document;
  const script = document.createElement('script');
  script.id = gtagScriptId;
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', trackingId, additionalConfigInfo);
}
 
export const gtag = function (...args: any[]) {
  window.dataLayer.push(args);
}

export const trackConversionEvent = (gtmGroup: string) => {
  gtag('event', 'conversion', {'send_to': gtmGroup })
}