import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import * as googleHelpers from 'util/google-helpers';
import * as constants from 'util/constants';
import { style } from './style';
import history from 'util/history';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { DownArrowIcon } from 'util/icons';

class Dropdown extends React.Component<models.store.IAppProps> {
  private styles: any;

  public state = ({
    isMenuOpen: false
  });

  public componentDidUpdate(prevProps: models.store.IAppProps): void {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.setState({ isMenuOpen: false })
    }
  }

  handleMenuToggle() {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.DROPDOWN_TOGGLE, '');
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  render() {
    const { dropdown, global } = this.props.stylesData;
    const categories = this.props.cmsData.data;
    const copy = this.props.copyData.text.navigation;

    this.styles = style({
      globalStyles: global,
      dropdownStyles: dropdown,
      isOpen: this.state.isMenuOpen
    });

    return (
      <nav className={css(this.styles.dropdown)} aria-label={copy.dropdown_button}>
        <button className={css(this.styles.button)} aria-haspopup="true" aria-expanded={this.state.isMenuOpen} onClick={() => this.handleMenuToggle()}>
          <span> { copy.dropdown_button } </span>
          <DownArrowIcon />
        </button>

        { this.state.isMenuOpen &&
          <ul className={css(this.styles.menu)}>
            { categories.map((category: any) => {
              const slug = helpers.normalizeForUrl(category.name);
              const isActive = helpers.checkIfTrue(category.active);
              const isSelected = this.props.match.params.name === slug;

              return (
                <li className={`${css(this.styles.link)} ${!isActive? css(this.styles.disabled) : ''} ${isSelected? css(this.styles.selected) : ''}`} aria-disabled={isActive? 'false' : 'true'} key={slug}>
                  <Link to={{ pathname: (isActive? slug : ''), search: history.location.search}}>
                    { category.title }
                  </Link>
                </li>
              )
            }) }
          </ul>
        }
      </nav>
    );
  }
}

export default withRouter(Connect(Dropdown));
