import * as models from 'models/index';
import { IAppProps } from 'models/store';
import { AppStateProvider } from 'store/index';

export function closeModal(this: AppStateProvider): Promise<IAppProps> {
  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        modalProps: {
          type: '',
          redirectToVote: false
        }
      };
    }, () => resolve(this));
  });
};

export function openModal(this: AppStateProvider, type: string, redirectToVote?: boolean): Promise<IAppProps> {
  const { modalProps } = this.state;

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        modalProps: {
          ...modalProps,
          type,
          redirectToVote
        }
      };
    }, () => resolve(this));
  });
};
