import * as models from 'models/index';

function Video(props: models.video.IVideoProps) {

  return (
    <iframe title={props.title || 'iframe-video-player'} width="560" height="315" src={props.url}
    frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;"></iframe>
  )

}

export default Video;
