import { AppStateProvider } from 'store/index';
import { ACTION_TYPES } from 'util/constants';
import * as models from 'models/index';

export function getUserPayload(this: AppStateProvider): any {
  const payload = { ...this.state.globalProps.userData, ...this.state.globalProps.userData.optins } as models.base.IGenericObject;
  delete payload.email;
  delete payload.isAuthorized;
  delete payload.optins;
  delete payload.id;

  return payload;
}

export function getHistoryPayload(this: AppStateProvider, catId?: string): any {
  return {
    action_type: ACTION_TYPES.GET,
    apiKey: this.state.cmsData.settings.apiKey,
    method: this.state.globalProps.userData.method,
    timestamp: new Date().getTime(),
    user_id: this.state.globalProps.userData.user_id,
    ...(catId && {
      category: `cat${catId}`
    })
  };
}

export function getDefaultPayload(this: AppStateProvider, actionType: string, catId?: string): any {
  const { user_id, method } = this.state.globalProps.userData;

  return {
    action_type: actionType,
    apiKey: this.state.cmsData.settings.apiKey,
    timestamp: new Date().getTime(),
    user_id,
    method,
    ...(catId && {
      category: `cat${catId}`
    })
  };
}