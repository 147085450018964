import * as models from 'models/index';
import { children, darkenColor, media } from 'util/style-variables';
import { StyleSheet } from 'aphrodite/no-important';

export const style: any = (styles: models.base.IGenericObject) => StyleSheet.create({
  modal: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'block',
    overflow: 'auto',
    ...styles.modalStyles.overlay,

    [media.tablet]: {
      ...styles.modalStyles.overlay.tablet
    },

    [media.desktop]: {
      ...styles.modalStyles.overlay.desktop
    }
  },

  content: {
    position: 'relative',
    width: '100%',
    background: '#fff',
    maxWidth: '455px',
    ...styles.modalStyles.container,

    [media.tablet]: {
      ...styles.modalStyles.container.tablet
    },

    [media.desktop]: {
      ...styles.modalStyles.container.desktop
    }
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '30px',

    [media.tablet]: {
      alignItems: 'center',
      padding: '50px',
    }
  },

  close: {
    padding: '6px 6px 4px 6px',
    top: 2,
    right: 2,
    position: 'absolute',
    zIndex: 1,

    [media.tablet]: {
      top: -5,
      right: -35
    },

    [children('svg')]: {
      ...styles.modalStyles.closeButton,

      [media.tablet]: {
        ...styles.modalStyles.closeButton.tablet
      },

      [media.desktop]: {
        ...styles.modalStyles.closeButton.desktop
      }
    },

    ':hover svg': {
      fill: darkenColor(styles.modalStyles.closeButton.fill),

      [media.tablet]: {
        fill: styles.modalStyles.closeButton.tablet? darkenColor(styles.modalStyles.closeButton.tablet.fill) : ''
      },

      [media.desktop]: {
        fill: styles.modalStyles.closeButton.tablet? darkenColor(styles.modalStyles.closeButton.desktop.fill) : ''
      }
    }
  }
});
