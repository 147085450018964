import * as models from 'models/index';
import * as constants from 'util/constants';
import * as helpers from 'util/helpers';
import history from 'util/history';
import { AppStateProvider } from 'store/index';
import {
  RANDOM,
  RANDOMIZED,
  ALPHA,
  ALPHABETICAL,
  SORT_BY,
  ASCENDING,
  RANDOMIZE,
} from 'util/constants';
import { SortingMethod, SortingDirection } from 'models/grid';
import { IGenericObject } from 'models/base';

export function toggleSort(
  this: AppStateProvider,
  sortingMethod: SortingMethod,
  sortingDirection: SortingDirection = ASCENDING
): Promise<any> | null {
  const { gridProps, globalProps } = this.state;

  if (!globalProps.category?.contestants) return null;

  const method = sortingMethod.toLowerCase() as SortingMethod;
  const direction = sortingDirection.toLowerCase() as SortingDirection;
  const contestantOption = Object.values(SORT_BY).includes(method)
    ? method
    : null;

  switch (method) {
    case contestantOption:
      globalProps.contestants = helpers.sortArrByProp(
        globalProps.contestants,
        method,
        direction
      );
      gridProps.sortingMethod = (contestantOption ||
        SORT_BY.NAME) as SortingMethod;
      break;
    case RANDOM:
    case RANDOMIZE:
    case RANDOMIZED:
      globalProps.contestants = helpers.shuffleArr(globalProps.contestants);
      gridProps.sortingMethod = RANDOMIZED;
      break;
    case ALPHABETICAL:
    case ALPHA:
    default:
      globalProps.contestants = helpers.sortArrByProp(
        globalProps.contestants,
        SORT_BY.NAME,
        direction
      );
      gridProps.sortingMethod = ALPHABETICAL;
  }

  gridProps.sortingDirection = direction;

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        gridProps,
        globalProps
      };
    }, () => resolve(this.state));
  });
};

export async function handleParams(this: AppStateProvider, params: { name?: string, detail?: string} = {}): Promise<any> {
  const name = params.name;
  const detail = params.detail;

  if (!name) return;

  const { globalProps, cmsData, contestantsData, isCategoryVote } = this.state;

  const categoryName = isCategoryVote? name : null;
  const contestantSlug = isCategoryVote? detail : name;

  const newState = {
    category: globalProps.category,
    contestantId: globalProps.contestantId
  }

  /* If Category Vote, check if name param matches any category name.
     If no categories are found, send user back to landing page
  */
  if (categoryName) {
    const category = Object.values(cmsData.data).find((cat: any) => {
      const nameUrl = helpers.normalizeForUrl(cat['name']);
      if ( nameUrl === categoryName ) return cat;
    }) as IGenericObject;

    if (category?.name) {
      newState.category = category;
    } else {
      history.push({
        pathname: '/',
        search: history.location.search,
      });
      return;
    }
  }

  // If Category Vote, check for contestant slug within a category
  if (contestantSlug) {
    const contestant = contestantsData.data.find((contestant: any) => {
      return (helpers.normalizeForUrl(contestant.name) === contestantSlug) &&
             (newState.category?.id? contestant.category_id === newState.category.id : true);
    });
    newState.contestantId = contestant?.id || '';
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps: {
          ...globalProps,
          ...newState
        }
      };
    }, () => resolve(this.state));
  });
}

export function createContestantList(this: AppStateProvider): Promise<any> {
  const { isCategoryVote, globalProps } = this.state;
  const categoryId = globalProps.category.id;
  const contestantsData = this.state.contestantsData.data;
  const displayEliminated = helpers.checkIfTrue(this.state.cmsData.text.grid.settings.display_eliminated);

  const filteredContestants = displayEliminated? contestantsData :
    contestantsData.filter((contestant: models.cms.ICmsOptions) =>
      !helpers.checkIfTrue(contestant.is_eliminated)
  );

  let contestants = filteredContestants;

  if (isCategoryVote) {
    contestants = filteredContestants.filter((contestant: models.cms.ICmsOptions) => {
      return categoryId === contestant.category_id
    })
  }

  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps: {
          ...globalProps,
          contestants
        }
      };
    }, () => resolve(this.state));
  });
}

export function getContestantById(this: AppStateProvider, id: string): any {
  const { data } = this.state.contestantsData;
  const { globalProps: { category } } = this.state

  return data.find((constestant: any) => {    
    if (( constestant.id === id ) && 
        (category?.id ? category.id === constestant.category_id : true)) { 
      return constestant;
    }
  })
}


export function displayContestantModal(this: AppStateProvider, id: string) {
  const { display_confirmation } = this.state.cmsData.text.grid.settings;

  if (!helpers.checkIfTrue(display_confirmation) || !id) {
    const categoryName = helpers.normalizeForUrl(this.state.globalProps.category.name);
    const pathname = this.state.isCategoryVote? `/${categoryName}` : '/';

    return history.push({
      pathname,
      search: history.location.search,
    });
  }

  this.state.modalFn.openModal(constants.MODAL_TYPES.vote);
};
