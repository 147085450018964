import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as helpers from 'util/helpers';
import * as googleHelpers from 'util/google-helpers';
import * as constants from 'util/constants';
import { style } from './style';
import { Link } from 'react-router-dom';
import history from 'util/history';

class Categories extends React.Component<models.store.IAppProps> {

  public componentDidMount(): void {
    googleHelpers.trackGooglePage(constants.GA_PAGES.CATEGORIES);
    this.props.globalFn.scrollToTop();
  }

  // TODO: create category interface to replace :any
  render() {
    const categories = this.props.cmsData.data;
    const copy = this.props.copyData.text.categories;
    const { stylesData } = this.props;

    const styles = style({
      globalStyles: stylesData.global,
      categoryStyles: stylesData.categories
    });

    return (
      <>
      { this.props.children }

      <section className={css(styles.categories)}>
        { copy.headline &&
          <h1 className={css(styles.headline)}>
            { copy.headline }
          </h1>
        }

        { copy.description &&
          <h1 className={css(styles.description)}>
            { copy.description }
          </h1>
        }

        <ul className={css(styles.categoriesContainer)}>
          { categories.map((category: any, index: number) => {
            const slug = helpers.normalizeForUrl(category.name);
            const isActive = helpers.checkIfTrue(category.active);
            return (
              <li key={index} className={css(styles.category)}>
                <Link to={{ pathname: (isActive? slug : ''), search: history.location.search}} key={slug}
                      className={`${css(styles.button)} ${!isActive? css(styles.disabled) : ''}`}
                      aria-disabled={isActive? 'false' : 'true'} data-track={category.name}>
                  { category.name }
                </Link>
              </li>
            )
          }) }
        </ul>
      </section>
      </>
    );
  }
}

export default Connect(Categories);
