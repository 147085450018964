import { IEmailLoginPayload, IFacebookLoginPayload, ITwitterLoginPayload } from 'models/auth';
import * as models from 'models/index';
import { AppStateProvider } from 'store/index';
import api from 'util/api';
import { ACTION_TYPES } from 'util/constants';

export const initialUserData = {
  // Settings
  isAuthorized: false,
  method: '',
  // Default
  optins: {},
  user_id: '',
  // Email
  email: '',
  // Facebook
  facebook_email: '',
  fb_first_name: '',
  fb_last_name: '',
  // Twitter
  twitter_email: '',
  display_name: '',
  screen_name: ''
}

export function login(this: AppStateProvider, payload: IEmailLoginPayload | ITwitterLoginPayload | IFacebookLoginPayload): Promise<models.store.IAppProps> {
  return new Promise<any>((resolve: (value: any) => void | undefined) => {
    this.setState((prevState: models.store.IAppProps) => {
      return {
        ...prevState,
        globalProps: {
          ...this.state.globalProps,
            userData: {
              isAuthorized: true,
              ...payload
            }
          }
        };
      }, () => resolve(this));
    });
}

export function logout(this: AppStateProvider): void {
  this.setState((prevState: models.store.IAppProps) => {
    return {
      ...prevState,
      globalProps: {
        ...this.state.globalProps,
        userData: initialUserData
      },
      voteHistory: {}
    };
  });
}

export async function submitLogin(this: AppStateProvider, userPayload:IEmailLoginPayload | ITwitterLoginPayload | IFacebookLoginPayload ): Promise<any> {  
  const payload = {
    action_type: ACTION_TYPES.LOGIN,
    apiKey: this.state.cmsData.settings.apiKey,
    timestamp: new Date().getTime(),
    ...userPayload
  };

  return await api.postToVoteApi(payload, this.state.cmsData.settings.version_id);
}

export async function submitRegistration(this: AppStateProvider, userPayload:IEmailLoginPayload | ITwitterLoginPayload | IFacebookLoginPayload ): Promise<any> {
  const payload = {
    action_type: ACTION_TYPES.REGISTER,
    apiKey: this.state.cmsData.settings.apiKey,
    timestamp: new Date().getTime(),
    ...userPayload
  };

  return await api.postToVoteApi(payload, this.state.cmsData.settings.version_id);
}
