import * as React from 'react';
import { css } from 'aphrodite/no-important';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { style } from './style';
import { withRouter } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import Panels from 'components/panels/index';
import { SortingMethod } from 'models/grid';

class Grid extends React.Component<models.store.IAppProps> {
  private styles: any;

  public async componentWillMount(): Promise<any> {
    await this.setUpGrid();
    await this.props.voteFn.getVoteHistory();
  }

  public async componentDidMount(): Promise<any> {

    const sortMethod = this.props.cmsData.text.grid.settings
      .default_sorting_method as SortingMethod;
    const sortDirection = this.props.cmsData.text.grid.settings
      .sorting_direction;

    googleHelpers.trackGooglePage(this.props.match.params.name || this.props.match.path);

    await this.props.gridFn.toggleSort(sortMethod, sortDirection);

    const { detail, name } = this.props.match.params;
    const contestantSlug = this.props.isCategoryVote? detail : name;

    if (contestantSlug) {
      this.props.gridFn.displayContestantModal(this.props.globalProps.contestantId);
    }
  }

  private async setUpGrid() {
    await this.props.gridFn.handleParams(this.props.match.params);
    await this.props.gridFn.createContestantList();
  }

  public async componentDidUpdate(prevProps: models.store.IAppProps): Promise<any> {

    const sortMethod = this.props.cmsData.text.grid.settings
      .default_sorting_method as SortingMethod;
    const sortDirection = this.props.cmsData.text.grid.settings
      .sorting_direction;

    if (prevProps.globalProps.userData.isAuthorized !== this.props.globalProps.userData.isAuthorized) {
      await this.props.voteFn.getVoteHistory();
    }

    if (prevProps.match.params.name !== this.props.match.params.name) {
      if (this.props.isCategoryVote) {
        await this.setUpGrid();
        await this.props.gridFn.toggleSort(sortMethod, sortDirection);
        await this.props.voteFn.getVoteHistory();
      }

      this.props.globalFn.scrollToTop();
    }

    if ( (JSON.stringify(prevProps.cmsData.data) !== JSON.stringify(this.props.cmsData.data)) ||
          (JSON.stringify(prevProps.contestantsData.data) !== JSON.stringify(this.props.contestantsData.data)) ||
          prevProps.cmsData.text.grid.settings.display_eliminated !== this.props.cmsData.text.grid.settings.display_eliminated ) {

      await this.setUpGrid();
      await this.props.gridFn.toggleSort(sortMethod, sortDirection);
    }
  }

  _renderRemainingVotes() {
    const { category, contestants } = this.props.globalProps;

    const total = this.props.voteFn.getCategoryVotes();
    const maxVotes = this.props.cmsData.text.vote_settings.max_votes_per_category;
    const remainingVotes = maxVotes - total < 0? 0 : maxVotes - total;

    const copyKey = remainingVotes === 1? 'singular' : 'plural';
    const countCopy = this.props.copyData.text.grid.vote_count_label[copyKey];
    const label = reactStringReplace(countCopy, '{{COUNT}}', (match, i) => (
      <span className={css(this.styles.count)} key={`count-${category.id}`}> { remainingVotes } </span>
    ));

    return <p className={css(this.styles.countLabel)} key={category.id}> { label } </p>;
  }

  public render(): React.ReactNode {
    const { settings } = this.props.cmsData.text.grid;
    const { category, userData } = this.props.globalProps;
    const { voteHistory } = this.props;
    const copy = this.props.copyData.text.grid;
    const { columns } = this.props.stylesData.grid;

    this.styles = style({
      columns: {
        mobile: (parseInt(columns.mobile) > constants.COLUMNS.MOBILE)? 2 : columns.mobile,
        tablet: (parseInt(columns.tablet) > constants.COLUMNS.TABLET)? 4 : columns.tablet,
        desktop: (parseInt(columns.desktop) > constants.COLUMNS.DESKTOP)? 6 : columns.desktop
      },
      globalStyles: this.props.stylesData.global,
      gridStyles: this.props.stylesData.grid
    });

    return (
      <section className={css(this.styles.grid)}>
        <div className={css(this.styles.text_wrapper)} >

          { voteHistory && userData.isAuthorized && helpers.checkIfTrue(settings.display_vote_count) &&
            this._renderRemainingVotes() }

          <div aria-live="polite" aria-atomic="true" role="region">
            <h1 className={css(this.styles.headline)}
                dangerouslySetInnerHTML={{ __html: this.props.isCategoryVote? category.title : copy.headline }} />
          </div>

          <div className={css(this.styles.subheadline_wrapper)}>
            <p className={css(this.styles.description)}>
              { this.props.isCategoryVote? category.description : copy.description }
            </p>

            {helpers.checkIfTrue(settings.display_sorting_methods) &&
              this._renderSortingMethods()}
          </div>

        </div>
        
        <section className={css(this.styles.panel_window)}>
          <Panels />

          {this.props.children}
        </section>

        {copy.additional_info &&
          <p className={css(this.styles.additional_info)}> {copy.additional_info} </p>}

      </section>
    );
  }


  _renderSortingMethods = () => {
    const method = this.props.gridProps.sortingMethod;

    const randomCls =
      method === constants.RANDOMIZED
        ? this.styles.sort_button_active
        : this.styles.sort_button_inactive;
    const alphabeticalCls =
      method === constants.ALPHABETICAL
        ? this.styles.sort_button_active
        : this.styles.sort_button_inactive;

    return (
      <div className={css(this.styles.sort_wrapper)}>
        <span className={css(this.styles.sort_label)}>Sort By: </span>
        <button
          aria-label='Random sort'
          aria-pressed={method === constants.RANDOMIZED}
          onClick={() => {
            this.props.gridFn.toggleSort(constants.RANDOMIZED);
          }}
          className={css(randomCls)}
        >
          Random
        </button>
        <span aria-hidden='true'> | </span>
        <button
          aria-label='Alphabetical sort'
          aria-pressed={method === constants.ALPHABETICAL}
          onClick={() => {
            this.props.gridFn.toggleSort(constants.ALPHABETICAL);
          }}
          className={css(alphabeticalCls)}
        >
          Alphabetical
        </button>
      </div>
    );
  };
}

export default withRouter(Connect(Grid));
