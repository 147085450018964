import * as React from 'react';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { css } from 'aphrodite/no-important';
import Button from 'components/button';
import * as models from 'models/index';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import * as helpers from 'util/helpers';
import { style } from './style';
import { useVoteAttempt } from 'store/vote';
import { withRouter } from 'react-router-dom';

type PanelsProps = models.store.IAppProps & models.global.IGenericProps;

function Panels(props: PanelsProps) {
  const { voteAttempt, setVoteAttempt, isUserValid } = useVoteAttempt(props.globalProps.userData);

  const { userData, contestants } = props.globalProps;
  const { voteHistory } = props;
  const buttonsData = props.copyData.text.nominee.buttons;
  const buttonsStyles = props.stylesData.nominee.buttons;
  const { settings } =  props.cmsData.text.nominee;
  const isWindowOpen = helpers.checkIfTrue(props.cmsData.settings.window_status);
  const isDetailsEnabled = helpers.checkIfTrue(props.cmsData.text.closed.settings.enable_contestants_details);
  const hasWinner = contestants.find((contestant: models.cms.ICmsOptions) => helpers.checkIfTrue(contestant.winner));
  const displayWinnerBanner = hasWinner && helpers.checkIfTrue(props.cmsData.text.nominee.settings.display_winner_banner);
  const displayButton = isWindowOpen || helpers.checkIfTrue(props.cmsData.text.closed.settings.display_details_button);
  const displayConfirmation = helpers.checkIfTrue(
    props.cmsData.text.grid.settings.display_confirmation
  );

  const styles = style({
    globalStyles: props.stylesData.global,
    panelStyles: props.stylesData.nominee
  });

  function _renderVoteCount(id: string) {
    const count = props.voteFn.getContestantVotes(id);
    const copyKey = count === 1? 'singular' : 'plural';

    return (
      <p key={id}>
        <span className={css(styles.coun)}> { count }  </span>
        <span className={css(styles.countLabel)}> { props.copyData.text.nominee.vote_count_label[copyKey] } </span>
      </p>
    )
  }

  useEffect(() => {
    if (isUserValid && props.modalProps.type === constants.MODAL_TYPES.login) {

      if (props.modalProps.redirectToVote) {
        props.modalFn.openModal(constants.MODAL_TYPES.vote);
      } else {
        props.modalFn.closeModal();
      }

      if (voteAttempt) { setVoteAttempt(false) }
    }

    if (voteAttempt && !isUserValid && !props.modalProps.type) {
      props.modalFn.openModal(constants.MODAL_TYPES.login)
      setVoteAttempt(false);
    }
  }, [voteAttempt, isUserValid, props.modalProps.type])

  return contestants.map((contestant: models.cms.ICmsOptions, i: number) => {
    const isEliminated = helpers.checkIfTrue(contestant.is_eliminated);
    const { vote, eliminated, closed_disabled, closed_details } = buttonsData;

    const buttonData = isWindowOpen ? vote :
                      isEliminated? eliminated :
                      isDetailsEnabled? closed_details : closed_disabled;

    const buttonStyles = isWindowOpen ? buttonsStyles.vote :
                        isEliminated? eliminated :
                        isDetailsEnabled? buttonsStyles.closedDetails : buttonsStyles.closedDisabled;

    return (
      <div className={css(styles.panel_wrapper)} key={i}>
        <div className={css(styles.panel)}>
          <LazyLoadImage
            alt=''
            src={contestant.image} />

          <div className={css(styles.text_wrapper)}>
            <h2 className={css(styles.headline)}
                aria-label={helpers.checkIfTrue(contestant.winner)?
                props.copyData.text.nominee.winner.replace('{{NAME}}', contestant.name) : ''}>
                { contestant.name }
            </h2>

            { helpers.checkIfTrue(settings.display_description_1) && settings.display_description_1 &&
              <p className={css(styles.description_1)}>{contestant.description_1}</p> }

            { helpers.checkIfTrue(settings.display_description_2) && settings.display_description_2 &&
              <p className={css(styles.description_2)}>{contestant.description_2}</p> }

            { helpers.checkIfTrue(settings.display_description_3) && settings.display_description_3 &&
              <p className={css(styles.description_3)}>{contestant.description_3}</p> }

            { voteHistory && userData.isAuthorized && helpers.checkIfTrue(settings.display_vote_count) && !displayWinnerBanner &&
              _renderVoteCount(contestant.id) }

            { !displayWinnerBanner && displayButton &&
            <Button ariaLabel={`vote for ${contestant.name}`}
                    buttonData={buttonData}
                    buttonStyles={buttonStyles}
                    options={{
                      globalStyles: props.stylesData.global.buttons,
                      disabled: !(isWindowOpen || isDetailsEnabled)
                    }}
                    onClick={() => _handleClick(contestant.id)} />  }
          </div>

          { helpers.checkIfTrue(contestant.winner) && displayWinnerBanner &&
              <p className={css(styles.winner_banner)}> { props.copyData.text.nominee.winner } </p> }
        </div>
      </div>
    );
  });

  function _handleClick(id: string) {

    const { globalProps: { category, contestantId } } = props;
    const gaAction = `${constants.GA_EVENTS.SELECT_CONTESTANT}: ${category.id}: ${id}`;
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, gaAction,'');
    props.globalFn.setContestantId(id);

    const voteCount = props.voteFn.getContestantVotes(contestantId);

      // If the voting is 'multi-vote', then we will always need to display the vote modal,
      // so override if false
      if (displayConfirmation || props.isMultiVote) {
        return props.modalFn.openModal(constants.MODAL_TYPES.vote);
      }
      
      return isUserValid? props.voteFn.submitVote(voteCount + 1): setVoteAttempt(true);
  };
}

export default withRouter(Connect(Panels));
